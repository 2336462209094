import React from 'react';
import { Box } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MessageBubble = ({ message, sender }) => {
  return (
    <Box
      className="markdown"
      bg={sender === 'User' ? 'blue.500' : 'teal.500'}
      p={3}
      borderRadius="md"
      color="white"
      boxShadow="base"
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {message}
      </ReactMarkdown>
    </Box>
  );
};

export default MessageBubble;
