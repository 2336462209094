/**
 * Extracts CSS styles from an HTML string using regular expressions, assigns random IDs to elements with inline styles
 * if they don't already have one, and returns the styles and modified markup.
 *
 * @param {string} htmlString - The HTML string to extract CSS styles from.
 * @returns {[string, {elementSelector: string, cssStyle: string}[], string]} - An array containing the CSS style strings,
 * the styles extracted from attributes, and the modified markup with random IDs.
 */
export function pullStylesAndModifyHTML(htmlString) {
  const styleTagRegex = /<style[^>]*>([\s\S]*?)<\/style>/gi;
  const styleAttrRegex = /<([^>]+) style=["']([^"']*)["']/gi;
  
  let styleTagStyles = '';
  let styleAttrStyles = [];
  let modifiedMarkup = htmlString;
  let match;

  function generateUniqueId() {
    return 'id-' + Math.random().toString(36).substr(2, 9);
  }

  // Extract styles from <style> tags
  while ((match = styleTagRegex.exec(htmlString)) !== null) {
    styleTagStyles += match[1].trim();
  }

  // Extract styles from style attributes and assign random class names
  while ((match = styleAttrRegex.exec(htmlString)) !== null) {
    const elementAttributes = match[1];
    const cssStyle = match[2].trim();
    const idMatch = elementAttributes.match(/id=["']([^"']*)["']/);
    const uniqueId = idMatch ? idMatch[1] : generateUniqueId();
    
    // Add the new ID to the element in the modified markup if it doesn't already have one
    const modifiedElement = idMatch
      ? match[0].replace(/style=["'][^"']*["']/, '').replace(elementAttributes, `${elementAttributes}`)
      : match[0].replace(/style=["'][^"']*["']/, '').replace(elementAttributes, `${elementAttributes} id="${uniqueId}"`);
    
    modifiedMarkup = modifiedMarkup.replace(match[0], modifiedElement);

    styleAttrStyles.push({ elementSelector: `#${uniqueId}`, cssStyle });
  }

  return [styleTagStyles, styleAttrStyles, modifiedMarkup];
}
