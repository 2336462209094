import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as LogoSVG } from './color-logo.svg';

const AnimatedLogo = (props) => {
    const [isHovered, setIsHovered] = useState(false);
  
  const arrowPath = "M75 50H25l20-20-4-4-28 28 28 28 4-4-20-20h50v-8z";




  return (
    <motion.svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 100 100"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#3C8DFF" stopOpacity="1" />
          <stop offset="100%" stopColor="#00FF7F" stopOpacity="1" />
        </linearGradient>
      </defs>
      <motion.g
        initial={{ opacity: 1 }}
        animate={{ opacity: isHovered ? 0 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <LogoSVG />
      </motion.g>
      <motion.path
        d={arrowPath}
        fill="url(#grad1)"
        initial={{ pathLength: 0, opacity: 0 }}
        animate={{ 
          pathLength: isHovered ? 1 : 0, 
          opacity: isHovered ? 1 : 0 
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      />
    </motion.svg>
  );
};

export default AnimatedLogo;
