// theme/components/button.js
import { mode } from "@chakra-ui/theme-tools";

export const Button = {
  baseStyle: {
    fontWeight: "medium",
    borderRadius: "xl",
  },
  sizes: {
    md: {
      fontSize: "1rem",
      px: "1.5rem",
      py: "0.75rem",
    },
    lg: {
      fontSize: "1.125rem",
      px: "2rem",
      py: "1rem",
    },
  },
  variants: {
    primary: (props) => ({
      bg: mode("primary.500", "primary.300")(props),
      color: "white",
      _hover: {
        bg: mode("primary.600", "primary.400")(props),
      },
    }),
    secondary: (props) => ({
      bg: mode("secondary.500", "secondary.300")(props),
      color: "black",
      _hover: {
        bg: mode("secondary.600", "secondary.400")(props),
      },
    }),
    outline: (props) => {
      const colorScheme = props.colorScheme || "primary";
      return {
        bg: "transparent",
        border: "1px solid",
        borderColor: mode(`${colorScheme}.500`, `${colorScheme}.300`)(props),
        color: mode(`${colorScheme}.500`, `${colorScheme}.300`)(props),
        _hover: {
          bg: mode(`${colorScheme}.50`, `${colorScheme}.800`)(props),
        },
      };
    },

    frostedGlass: {
      bg: "transparent",
      color: "white",
      _active: { bg: "blue.500", color: "white" },

      _hover: {
        bg: "rgba(255, 255, 255, 0.3)",
        backdropFilter: "blur(10px)",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "primary",
  },
};
