import React from 'react';
import { FEATURE_FLAGS } from './constants/featureFlags'

export const GlobalStyles = () => {
  const styles = FEATURE_FLAGS.ENABLESTYLEMANAGERPANEL 
  ? `
    .gjs-editor .gjs-cv-canvas {
      position: static;
    }

    .contentLoaderWrapper {
      height: 100%;
      width: 100%;
    }

    .gjs-editor-cont {
      height: 100% !important;
    }

    .chakra-button.history-btn:disabled {
      color: darkgray;
    }
  `
  : '';

  return (
    <style>
      {styles}
    </style>
  );
};
