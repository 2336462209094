import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Input,
  Textarea,
  HStack,
  Button,
  Checkbox,
  Tooltip,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";

import { FaWandMagicSparkles } from "react-icons/fa6";
import { InfoIcon } from "@chakra-ui/icons";
import { supabase } from "../Auth/supabaseClient";
import { useSite } from "./SiteContext";
const TextareaWithGenerate = ({
  name,
  value,
  onChange,
  placeholder,
  onGenerate,
  isLoading,
}) => (
  <Box position="relative">
    <Textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      fontSize={"sm"}
      pr="40px"
    />
    <IconButton
      icon={<FaWandMagicSparkles />}
      size="xs"
      variant="outline"
      colorScheme={"secondary"}
      position="absolute"
      right="2"
      top="2"
      onClick={() => onGenerate(name)}
      aria-label={`Generate ${name}`}
      isLoading={isLoading}
      disabled={isLoading}
    />
  </Box>
);

const SEO = () => {
  const { pageId, currentPageVersionsId } = useSite();
  const toast = useToast();
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [originalSeoData, setOriginalSeoData] = useState({});
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    og_title: "",
    og_description: "",
    index: true, // Default to true
    follow: true, // Default to true
  });
  const [loadingFields, setLoadingFields] = useState({
    title: false,
    description: false,
    og_title: false,
    og_description: false,
  });
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);

  const handleGenerateSEO = async (field = null) => {
    try {
      const fieldsToGenerate = field
        ? [field]
        : ["title", "description", "og_title", "og_description"];

      if (field) {
        setLoadingFields((prev) => ({ ...prev, [field]: true }));
      } else {
        setIsGeneratingAll(true);
      }

      const { data, error } = await supabase.functions.invoke(
        "seo-generation",
        {
          body: JSON.stringify({
            versionId: currentPageVersionsId,
            pageId,
            fieldsToGenerate,
          }),
        }
      );

      if (error) throw error;

      setSeoData((prevData) => ({ ...prevData, ...data }));
      setHasChanges(true);

      toast({
        title: "SEO Generated",
        description: "SEO content has been successfully generated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error generating SEO:", error);
      toast({
        title: "Error",
        description: "Failed to generate SEO content.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      if (field) {
        setLoadingFields((prev) => ({ ...prev, [field]: false }));
      } else {
        setIsGeneratingAll(false);
      }
    }
  };

  useEffect(() => {
    fetchSEOData();
    const channel = supabase
      .channel(`SEO:page_id=${pageId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "SEO",
          filter: `page_id=eq.${pageId}`,
        },
        (payload) => {
          setSeoData(payload.new);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [pageId]);

  const fetchSEOData = async () => {
    const { data, error } = await supabase
      .from("seo")
      .select("*")
      .eq("page_id", pageId)
      .single();

    if (error) {
      console.error("Error fetching SEO data:", error);
    } else if (data) {
      setSeoData({
        ...data,
        index: data.index !== false, // Default to true if not explicitly false
        follow: data.follow !== false, // Default to true if not explicitly false
      });
      setOriginalSeoData({
        ...data,
        index: data.index !== false,
        follow: data.follow !== false,
      });
    }
  };

  const handleResetChanges = () => {
    fetchSEOData();
    setHasChanges(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSeoData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setHasChanges(true);
  };

  const handleSaveSEO = async () => {
    setIsSaving(true);
    const { data, error } = await supabase
      .from("seo")
      .upsert({ ...seoData, page_id: pageId }, { onConflict: ["page_id"] });

    setIsSaving(false);

    if (error) {
      console.error("Error saving SEO data:", error);
      toast({
        title: "Error",
        description: "Failed to save SEO information.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setIsSaved(true);
      setHasChanges(false);
      setTimeout(() => setIsSaved(false), 2000); // Reset "Saved" state after 2 seconds
    }
  };
  return (
    <Box bg="gray.800" p={4} color="white">
      <HStack mb={4}>
        <AiOutlineSearch size={24} />

        <Text fontSize="lg" fontWeight="bold">
          SEO Settings
        </Text>
      </HStack>
      <Text fontSize="sm" mb={4} color="gray.300">
        These settings help optimize your page for search engines. They apply only to this page
      </Text>

      <Button
        leftIcon={<FaWandMagicSparkles />}
        colorScheme="secondary"
        onClick={() => handleGenerateSEO()}
        flex={1}
        variant="outline"
        isLoading={isGeneratingAll}
        disabled={isGeneratingAll}
      >
        Generate SEO
      </Button>

      <VStack spacing={4} mt={4} overflow="auto" align="stretch">
        <Box>
          <Text mb={2}>Page Title</Text>
          <TextareaWithGenerate
            name="title"
            value={seoData.title}
            onChange={handleInputChange}
            placeholder="Enter page title"
            onGenerate={handleGenerateSEO}
            isLoading={loadingFields.title}
          />
        </Box>
        <Box>
          <Text mb={2}>Meta Description</Text>
          <TextareaWithGenerate
            name="description"
            value={seoData.description}
            onChange={handleInputChange}
            placeholder="Enter meta description"
            onGenerate={handleGenerateSEO}
            isLoading={loadingFields.description}
          />
        </Box>
        <Box>
          <Text mb={2}>OG Title</Text>
          <TextareaWithGenerate
            name="og_title"
            value={seoData.og_title}
            onChange={handleInputChange}
            placeholder="Enter Open Graph title"
            onGenerate={handleGenerateSEO}
            isLoading={loadingFields.og_title}
          />
        </Box>
        <Box>
          <Text mb={2}>OG Description</Text>
          <TextareaWithGenerate
            name="og_description"
            value={seoData.og_description}
            onChange={handleInputChange}
            placeholder="Enter Open Graph description"
            onGenerate={handleGenerateSEO}
            isLoading={loadingFields.og_description}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox
            name="index"
            isChecked={seoData.index}
            onChange={handleInputChange}
          >
            Index this page
          </Checkbox>
          <Tooltip
            label={
              <Box>
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Index:
                </Text>{" "}
                <Text as="span" fontSize="sm">
                  Allow search engines to show this page in search results.
                </Text>
                <br />
                <Text as="span" fontWeight="bold" fontSize="sm">
                  NoIndex:
                </Text>{" "}
                <Text as="span" fontSize="sm">
                  Ask search engines not to show this page in search results.
                </Text>
              </Box>
            }
            placement="top"
            hasArrow
          >
            <IconButton
              aria-label="Index information"
              icon={<InfoIcon />}
              size="xs"
              variant="frostedGlass"
              ml={2}
            />
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox
            name="follow"
            isChecked={seoData.follow}
            onChange={handleInputChange}
          >
            Follow links on this page
          </Checkbox>
          <Tooltip
            label={
              <Box>
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Follow:
                </Text>{" "}
                <Text as="span" fontSize="sm">
                  Allow search engines to follow links on this page.
                </Text>
                <br />
                <Text as="span" fontWeight="bold" fontSize="sm">
                  NoFollow:
                </Text>{" "}
                <Text as="span" fontSize="sm">
                  Ask search engines not to follow links on this page.
                </Text>
              </Box>
            }
            placement="top"
            hasArrow
          >
            <IconButton
              aria-label="Follow information"
              icon={<InfoIcon />}
              size="xs"
              variant="frostedGlass"
              ml={2}
            />
          </Tooltip>
        </Box>

        <Box display="flex" justifyContent="end" mt={4} gap={2}>
          {hasChanges && (
            <Button
              variant="outline"
              onClick={handleResetChanges}
              isDisabled={!hasChanges}
              width="125px"
            >
              Reset Changes
            </Button>
          )}

          <Button
            variant="primary"
            onClick={handleSaveSEO}
            isDisabled={!hasChanges || isSaving}
            isLoading={isSaving}
            loadingText="Saving..."
            width="150px"
          >
            {isSaved ? "Saved!" : "Save SEO Settings"}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default SEO;
