// OnboardingChatInterface.js
import React, { useState, useEffect, useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { supabase } from "../Auth/supabaseClient";
import ChatComponent from "../Utilities/ChatComponent";
import { Flex } from "@chakra-ui/react";

const OnboardingChatInterface = ({
  siteCreated,
  setSiteCreated,
  siteId,
  setSiteId,
  currentPageVersionsId,
  setcurrentPageVersionsId,
}) => {
  const [messages, setMessages] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [MESSAGE, setMESSAGE] = useState("");
  const [isTyping] = useState(false);

  const toast = useToast();
  const fetchMessages = useCallback(async () => {
    if (!siteId || !currentPageVersionsId) return;

    try {
      const { data, error } = await supabase
        .from("ai_messages")
        .select()
        .eq("site_id", siteId)
        .eq("version", currentPageVersionsId)
        .order("created_at", { ascending: true });

      if (error) {
        throw error;
      }
      setMessages(
        data.map((msg) => ({
          ...msg,
          sender: msg.from_user ? "User" : "Assistant",
        }))
      );
    } catch (error) {
      console.error("Error fetching messages:", error.message);
      toast({
        title: "Error",
        description: "Failed to fetch messages",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [siteId, currentPageVersionsId, toast]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const createSite = async () => {
    const { data, error } = await supabase
      .from("site")
      .insert([{ name: "New Site" }])
      .select("id");

    if (error) {
      console.error("Error creating site:", error.message);
      toast({
        title: "Error creating site",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return null;
    }

    if (data && data.length > 0) {
      return data[0].id;
    } else {
      console.error("Failed to retrieve the site ID from the response.");
      return null;
    }
  };

  const fetchVersionContent = async (siteId) => {
    if (!siteId) return null;

    const { data, error } = await supabase
      .from("page_versions")
      .select("id")
      .eq("site_id", siteId)
      .single();

    if (error || !data) {
      console.error("Error fetching version content:", error?.message);
      return null;
    }

    return data.id;
  };

  const handleFirstSend = async () => {
    setIsButtonDisabled(true);

    if (MESSAGE.trim() !== "") {
      let localSiteId = siteId;
      let localcurrentPageVersionsId = currentPageVersionsId;
      if (siteId && currentPageVersionsId) {
        siteCreated = true;
      }
      if (!siteCreated) {
        localSiteId = await createSite();
        if (!localSiteId) {
          console.error("Failed to create the site");
          return;
        }
        setSiteId(localSiteId);
        setSiteCreated(true);

        localcurrentPageVersionsId = await fetchVersionContent(localSiteId);
        if (!localcurrentPageVersionsId) {
          console.error("Failed to fetch version information");
          return;
        }
        setcurrentPageVersionsId(localcurrentPageVersionsId);
      }

      handleSendMessage(MESSAGE, localSiteId, localcurrentPageVersionsId);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "User", message: MESSAGE },
      ]);
      setMESSAGE("");
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 1000);
    }
  };

  const handleSendMessage = async (message, siteId, versionId) => {
    try {
      const { error } = await supabase
        .from("ai_messages")
        .insert([
          { message, from_user: true, site_id: siteId, version: versionId },
        ]);

      if (error) throw error;

      async function fetchData() {
        let attempts = 0;
        const maxAttempts = 3;
        const retryDelay = 2000;

        while (attempts < maxAttempts) {
          try {
            const { data: sessionData, error: sessionError } =
              await supabase.auth.getSession();

            if (sessionError || !sessionData.session) {
              throw new Error(
                "Failed to get session or no active session found"
              );
            }

            const jwt = sessionData.session.access_token;

            const response = await fetch(
              "https://rbfdagomafltuzfmfmgk.supabase.co/functions/v1/add-message",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                  message,
                  siteId,
                  currentPageVersionsId: versionId,
                }),
              }
            );

            if (!response.body) {
              console.log("No response body");
              return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let currentMessageId = Date.now();
            let buffer = "";

            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              buffer += decoder.decode(value, { stream: true });

              const chunks = buffer.split("\n");
              buffer = chunks.pop() || "";

              for (const chunk of chunks) {
                try {
                  const { delta, finalContent } = JSON.parse(chunk);
                  setMessages((prevMessages) => {
                    const existingIndex = prevMessages.findIndex(
                      (msg) => msg.id === currentMessageId
                    );
                    if (existingIndex >= 0) {
                      return prevMessages.map((msg, index) =>
                        index === existingIndex
                          ? { ...msg, message: finalContent }
                          : msg
                      );
                    } else {
                      return [
                        ...prevMessages,
                        {
                          id: currentMessageId,
                          message: finalContent,
                          sender: "Assistant",
                        },
                      ];
                    }
                  });
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                  console.log("Problematic JSON string:", chunk);
                }
              }
            }
            // Fetch the latest message from the ai_messages table
            const { data: latestMessage, error: fetchError } = await supabase
              .from("ai_messages")
              .select()
              .eq("site_id", siteId)
              .eq("version", versionId)
              .order("created_at", { ascending: false })
              .limit(1)
              .single();

            if (fetchError) {
              console.error(
                "Error fetching latest message:",
                fetchError.message
              );
            } else {
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.id === currentMessageId
                    ? { ...msg, message: latestMessage.message }
                    : msg
                )
              );
            }

            return;
          } catch (error) {
            console.error(
              "Attempt " + (attempts + 1) + " failed: ",
              error.message
            );
            attempts++;
            if (attempts < maxAttempts) {
              console.log(`Retrying in ${retryDelay / 1000} seconds...`);
              await new Promise((resolve) => setTimeout(resolve, retryDelay));
            } else {
              console.error("All attempts failed.");
              throw new Error(
                "Failed to fetch after " + maxAttempts + " attempts"
              );
            }
          }
        }
      }
      fetchData();
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "There was an error generating the site. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" height="100%">
      <ChatComponent
        messages={messages}
        setMessages={setMessages}
        handleSendMessage={handleFirstSend}
        isTyping={isTyping}
        isButtonDisabled={isButtonDisabled}
        setMessage={setMESSAGE}
        message={MESSAGE}
        fetchMessages={fetchMessages}
      />
    </Flex>
  );
};

export default OnboardingChatInterface;
