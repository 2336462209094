import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Heading,
  Image,
  useColorModeValue,
  Text,
  VStack,
  InputRightElement,
} from "@chakra-ui/react";
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FcGoogle } from "react-icons/fc";
import { supabase, useAuth } from "./supabaseClient";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "constants/routeConstants";
import { motion } from "framer-motion";

const AuthForm = ({
  heading,
  onFormSubmit,
  isSignUp = false,
  links,
  buttonLabel,
}) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const { session, loading, profileCompleteness } = useAuth();
  const [referrer, setReferrer] = React.useState('');

  useEffect(() => {
    const currentUrl = window.location.href;
    const referrerDomain = currentUrl ? currentUrl : '';
    setReferrer(referrerDomain);
  }, []);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session) {
        // Redirect to the referrer or a default route
        const redirectTo = referrer || ROUTE_PATHS.APP;
      }
    });
  
    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, [navigate, referrer]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(event, email, password, confirmPassword);
  };
  const handleGoogleSignIn = async () => {
    try {

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: referrer || window.location.origin
      },
      queryParams: {
        access_type: 'offline',
        prompt: 'consent',
    },

    });
  
    if (error) {
      // console.error('Error signing in with Google:', error);
      // You can also show an error message to the user here
    } else if (data) {
      // console.log('Google Sign-In successful:', data);
      // You can add additional logic here if needed
    }
  } catch (error) {
    console.error('Unexpected error during Google Sign-In:', error);
    // You can also show an error message to the user here
  }
};

  
  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue("brand.50", "brand.900")}
      p={{ base: 4, md: 10 }}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        w="full"
        maxW="1200px"
        mx="auto"
        justify="center"
        align="center"
        bg="white"
        rounded="2xl"
        boxShadow="2xl"
        overflow="hidden"
      >
        <Box
          w={{ base: "full", md: "100%" }}
          h="full"
          display={{ base: "none", md: "flex" }}
          justifyContent="center"
          alignItems="center"
          p={8}
        >
          <Image
            src="../media/welcomeillustration.webp"
            alt="Welcome!"
            objectFit="contain"
            maxH="400px"
            maxW="100%"
          />
        </Box>
        <VStack
          spacing={8}
          mx="auto"
          py={12}
          px={6}
          w="full"
          h="full" // Add this line
          bg="#3C8DFF"
          color="white"
        >
          <Image
            src="../logo/logotype-white.svg" // Adjust the path as needed
            alt="Company Logo"
            maxW="200px" // Adjust size as needed
            mb={4} // Add some margin bottom for spacing
          />

          <Heading fontSize={"3xl"} textAlign="center">
            {heading}
          </Heading>
          <Text fontSize="lg" textAlign="center">
            {isSignUp
              ? "Free to join and explore!"
              : "Please login to continue"}
          </Text>
          <Box
            rounded={"lg"}
            p={8}
            w="full"
            maxW={{ base: "380px", md: "500px" }}
            minW={{ base: "300px", sm: "380px" }}
          >
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} w="full">
                <FormControl id="email" w="full">
                  <FormLabel>Email address</FormLabel>{" "}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <EmailIcon color="brand.200" />
                      </InputLeftElement>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        bg="white"
                        color="gray.800"
                      />
                    </InputGroup>{" "}
                  </motion.div>
                </FormControl>
                <FormControl id="password" w="full">
                  <FormLabel>Password</FormLabel>{" "}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <LockIcon color="brand.200" />
                      </InputLeftElement>
                      <Input
                        type={show ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        bg="white"
                        color="gray.800"
                      />
                      <InputRightElement>
                        <Button
                          tabIndex="-1"
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShow(!show)}
                          bg="transparent"
                          _hover={{ bg: "brand.400" }}
                        >
                          {show ? (
                            <ViewOffIcon color="brand.200" />
                          ) : (
                            <ViewIcon color="brand.200" />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>{" "}
                  </motion.div>
                </FormControl>
                {isSignUp && (
                  <FormControl id="confirm-password" w="full">
                    <FormLabel>Confirm Password</FormLabel>{" "}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                    >
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <LockIcon color="brand.200" />
                        </InputLeftElement>
                        <Input
                          type={show ? "text" : "password"}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Confirm your password"
                          bg="white"
                          color="gray.800"
                        />
                      </InputGroup>
                    </motion.div>
                  </FormControl>
                )}

                <Button
                  type="submit"
                  w="full"
                  mt={4}
                  bg="white"
                  color="brand.500"
                  _hover={{ bg: "brand.100" }}
                >
                  {buttonLabel}
                </Button>
                <Button
                  leftIcon={<FcGoogle />}
                  w="full"
                  mt={2}
                  variant="outline"
                  bg="white"
                  color="brand.500"
                  _hover={{ bg: "brand.100" }}
                  onClick={handleGoogleSignIn}
                >
                  Sign in with Google
                </Button>
                <Flex
                  direction="row"
                  w="full"
                  mt={4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {links}
                </Flex>
              </VStack>
            </form>
          </Box>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default AuthForm;
