// App.js updates
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { tracker } from 'Utilities/TrackerInstance';
import { ROUTE_PATHS } from 'constants/routeConstants';
import './App.css';
import LoginPage from './Auth/LoginPage';
import SignupPage from './Auth/SignupPage';
import { AuthProvider, supabase } from './Auth/supabaseClient';
import HomePage from './HomePage';
import { GlobalStyles } from './PanelToggleStyles';
import SettingsPage from './SettingsPage';
import OnboardingUserStepper from './Onboarding-user/OnboardingStepper';
import PreviewPage from './Site/PreviewPage';
import SitePage from './Site/SitePage';
import Test from './Test';
import Workspace from './Workspace';
import ContentGuard from './guards/ContentGuard';
import LoginGuard from './guards/LoginGuard';
import store from './store/store';
import { theme } from './theme/index';

function App() {
  const { onOpen } = useDisclosure();
  useEffect(() => {
    tracker.start();
  
  
  
    const setUserIdForTracker = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        tracker.setUserID(user.email);
        tracker.setMetadata('url', window.location.href); 
        tracker.setMetadata('Url', window.location.href); 

      }
    };
  
    setUserIdForTracker();
  }, []);
  
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
          "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, [])


  return (
  <Provider store={store}>
    <GlobalStyles />
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Siteboon</title>
        <meta name="description" content="Create and maintain websites using AI using Siteboon, the AI first no-code website builder" />
      </Helmet>
      <Router>
        <AuthProvider>
          <Routes>
          <Route path="/" element={<Navigate to={ROUTE_PATHS.APP} replace />} />

            <Route path={ROUTE_PATHS.TEST} element={<Test />} />
            <Route path={ROUTE_PATHS.HOME} element={<HomePage />} />
            <Route path={ROUTE_PATHS.SIGNUP} element={
                <LoginGuard>
                  <SignupPage />
                </LoginGuard>
              }
            />
            <Route path={ROUTE_PATHS.LOGIN} element={
                <LoginGuard>
                  <LoginPage />
                </LoginGuard>
              }
            />
            <Route path={ROUTE_PATHS.COMPLETE_PROFILE} element={
                <ContentGuard>
                  <OnboardingUserStepper />
                </ContentGuard>
              }
            />
            <Route
              path={ROUTE_PATHS.PREVIEW}
              element={
                <ContentGuard>
                  <PreviewPage />
                </ContentGuard>
              }
            />
            <Route
              path={ROUTE_PATHS.SITE_WITH_ID}
              element={
                <ContentGuard>
                  <DndProvider backend={HTML5Backend}>
                    <SitePage onOpen={onOpen} />
                  </DndProvider>
                </ContentGuard>
              }
            />
            <Route
              path={ROUTE_PATHS.SITE_WITH_PAGE_NAME}
              element={
                <ContentGuard>
                  <DndProvider backend={HTML5Backend}>
                    <SitePage onOpen={onOpen} />
                  </DndProvider>
                </ContentGuard>
              }
            />
            <Route
              path={ROUTE_PATHS.APP}
              element={
                <ContentGuard>
                  <Workspace />
                </ContentGuard>
              }
            />
            <Route
              path={ROUTE_PATHS.SETTINGS}
              element={
                <ContentGuard>
                  <SettingsPage />
                </ContentGuard>
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  </Provider> 

  );
}

export default App;
