import React, { useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useMediaQuery,
  VStack,
  Collapse,
  Divider,
} from "@chakra-ui/react";
import { AiOutlineImport } from "react-icons/ai";

import {
  AiOutlineDeploymentUnit,
  AiOutlineProfile,
  AiOutlineFile,
  AiOutlineSearch,
} from "react-icons/ai";
import { FaComments } from "react-icons/fa";
import { FiClock } from "react-icons/fi";

import Header from "../Utilities/Header";
import LivePreview from "./LivePreview";
import Pages from "./Pages";
import ChatInterface from "./ChatInterface";
import ProfileComponent from "./WebsiteProfile";
import DeployComponent from "./Publish";
import VersionSelector from "./History";
import SEO from "./SEO";
import ImportComponent from './Import';
import { FEATURE_FLAGS } from "../constants/featureFlags";

import { SiteProvider } from "./SiteContext";
import { useParams } from "react-router-dom";
import AnimatedLogo from "../Utilities/AnimatedLogo";
import { useNavigate } from "react-router-dom";

function SitePage({ onOpen }) {
  const { id, pageName } = useParams();
  const [selectedModule, setSelectedModule] = useState("Chat");
  const [activeButton, setActiveButton] = useState("Chat");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const handleButtonClick = (module) => {
    if (selectedModule === module) {
      setSelectedModule("");
      setActiveButton("");
    } else {
      setSelectedModule(module);
      setActiveButton(module);
    }
  };

  const renderModuleContent = () => {
    switch (selectedModule) {
      case "Chat":
        return <ChatInterface />;
      case "Profile":
        return <ProfileComponent />;
      case "Deploy":
        return <DeployComponent />;
      case "Pages":
        return <Pages />;
      case "Version":
        return <VersionSelector />;
      case "SEO":
        return <SEO />; // Replace with your SEO component
      case "Import":
        return <ImportComponent />;

      default:
        return null;
    }
  };

  const mobilePanel = (
    <Box display={selectedModule ? "block" : "none"} width="100%" height="100%">
      <Flex
        direction="column"
        height="100%"
        bg="gray.800"
        p={4}
        borderRadius="lg"
        boxShadow="xl"
      >
        {renderModuleContent()}
      </Flex>
    </Box>
  );

  return (
    <SiteProvider siteId={id} pageName={pageName}>
      <Box bg="gray.100" height="100vh" overflow="hidden">
        {isMobile && <Header onOpen={onOpen} />}

        <Flex
          direction={{ base: "column", md: "row" }}
          overflowY="hidden"
          gap={{ base: 0, md: 4 }}
          height={{ base: "calc(100vh - 60px)", md: "100vh" }} // Adjust this line
        >
          {!isMobile && (
            <VStack
              position="fixed"
              left="0"
              top="0"
              height="100vh"
              width="70px"
              bg="primary.900"
              color="white"
              spacing={4}
              py={4}
              zIndex="overlay"
              boxShadow="lg"
            >
              <AnimatedLogo
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />

              <Tooltip
                label="Request Changes"
                placement="right"
                hasArrow
                bg="gray.700"
                p={2}
              >
                <IconButton
                  icon={<FaComments style={{ fontSize: "24px" }} />}
                  aria-label="Request Changes"
                  variant="frostedGlass"
                  size="md"
                  onClick={() => handleButtonClick("Chat")}
                  isActive={activeButton === "Chat"}
                />
              </Tooltip>
              <Tooltip
                label="History"
                placement="right"
                hasArrow
                bg="gray.700"
                p={2}
              >
                <IconButton
                  icon={<FiClock style={{ fontSize: "24px" }} />}
                  aria-label="Version History"
                  variant="frostedGlass"
                  size="md"
                  onClick={() => handleButtonClick("Version")}
                  isActive={activeButton === "Version"}
                />
              </Tooltip>
              <Tooltip
                label="Page SEO"
                placement="right"
                hasArrow
                bg="gray.700"
                p={2}
              >
                <IconButton
                  icon={<AiOutlineSearch style={{ fontSize: "24px" }} />}
                  aria-label="SEO"
                  variant="frostedGlass"
                  colorScheme="white"
                  size="md"
                  onClick={() => handleButtonClick("SEO")}
                  isActive={activeButton === "SEO"}
                />
              </Tooltip>
              {FEATURE_FLAGS.ENABLEIMPORT && (
                <Tooltip
                  label="Import HTML"
                  placement="right"
                  hasArrow
                  bg="gray.700"
                  p={2}
                >
                  <IconButton
                    icon={<AiOutlineImport style={{ fontSize: "24px" }} />}
                    aria-label="Import HTML"
                    variant="frostedGlass"
                    colorScheme="white"
                    size="md"
                    onClick={() => handleButtonClick("Import")}
                    isActive={activeButton === "Import"}
                  />
                </Tooltip>
              )}

              <Divider borderColor="gray.600" />
              <Tooltip
                label="Pages"
                placement="right"
                hasArrow
                bg="gray.700"
                p={2}
              >
                <IconButton
                  icon={<AiOutlineFile style={{ fontSize: "24px" }} />}
                  aria-label="Pages"
                  variant="frostedGlass"
                  size="md"
                  onClick={() => handleButtonClick("Pages")}
                  isActive={activeButton === "Pages"}
                />
              </Tooltip>
              <Tooltip
                label="Website Profile"
                placement="right"
                hasArrow
                bg="gray.700"
                p={2}
              >
                <IconButton
                  icon={<AiOutlineProfile style={{ fontSize: "24px" }} />}
                  aria-label="Website Profile"
                  variant="frostedGlass"
                  size="md"
                  onClick={() => handleButtonClick("Profile")}
                  isActive={activeButton === "Profile"}
                />
              </Tooltip>
              <Tooltip
                label="Publish/Deploy"
                placement="right"
                hasArrow
                bg="gray.700"
                p={2}
              >
                <IconButton
                  icon={
                    <AiOutlineDeploymentUnit style={{ fontSize: "24px" }} />
                  }
                  aria-label="Publish/Deploy"
                  variant="frostedGlass"
                  size="md"
                  onClick={() => handleButtonClick("Deploy")}
                  isActive={activeButton === "Deploy"}
                />
              </Tooltip>
            </VStack>
          )}

          {isMobile ? (
            <>
              {mobilePanel}
              <Box flex="1" overflow="auto" width="100%" height="100%">
                <LivePreview />
              </Box>
            </>
          ) : (
            <Flex
              flex="1"
              ml="70px"
              gap="4"
              overflowY="auto"
              height="100vh"
              pl="1"
              pb="2"
              pt="2"
            >
              <Collapse
                in={Boolean(selectedModule)}
                animateOpacity
                unmountOnExit
                style={{ transformOrigin: "right" }}
                animationDuration="0.3s"
              >
                <Box
                  flex="0 0 350px"
                  overflow="hidden"
                  ml="2px"
                  width="350px"
                  height="100%"
                >
                  <Flex
                    direction="column"
                    height="100%"
                    bg="gray.800"
                    p={4}
                    borderRadius="lg"
                    boxShadow="xl"
                  >
                    {renderModuleContent()}
                  </Flex>
                </Box>
              </Collapse>
              <Box
                flex={selectedModule ? "2" : "1"}
                overflow="auto"
                transition="flex 0.3s ease"
                width="100%"
                height="100%"
              >
                <LivePreview />
              </Box>
            </Flex>
          )}
          {isMobile && (
            <IconButton
              icon={<FaComments />}
              variant="solid"
              position="fixed"
              bottom="4"
              right="4"
              colorScheme="teal"
              size="lg"
              onClick={() => handleButtonClick("Chat")}
              zIndex="overlay"
            />
          )}
        </Flex>
      </Box>
    </SiteProvider>
  );
}

export default SitePage;
