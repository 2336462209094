/**
 * Calculates the distance of an element's edges to the window borders.
 *
 * @param {Object} options - The coordinates and size of the element.
 * @param {number} [options.top=0] - The top coordinate of the element.
 * @param {number} [options.left=0] - The left coordinate of the element.
 * @param {number} [options.width=0] - The width of the element.
 * @param {number} [options.height=0] - The height of the element.
 * @returns {Object} The distances to the window borders.
 * @returns {number} top - The distance to the top border of the window.
 * @returns {number} right - The distance to the right border of the window.
 * @returns {number} bottom - The distance to the bottom border of the window.
 * @returns {number} left - The distance to the left border of the window.
 */
export function getDistanceToWindowBorders(
  top = 0,
  left = 0,
  width = 0,
  height = 0
) {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const topDistance = top;
  const rightDistance = windowWidth - (left + width);
  const bottomDistance = windowHeight - (top + height);
  const leftDistance = left;

  return {
    top: topDistance,
    right: rightDistance,
    bottom: bottomDistance,
    left: leftDistance
  };
}
