import React from 'react';
import './Tooltip.css';

export const Tooltip = ({ label, children }) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className="tooltip-content">
        {label}
        <div className="tooltip-arrow"></div>
      </div>
    </div>
  );
};
