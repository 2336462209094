import {
  Box,
  Button,
  Text as ChakraText,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { FaMicrophone } from "react-icons/fa";
import { MdDeleteSweep } from "react-icons/md";
import MessageBubble from "../Utilities/MessageBubble";
import { FEATURE_FLAGS } from "../constants/featureFlags";
import "./ChatComponent.css";

const ChatComponent = ({
  messages,
  setMessages,
  handleSendMessage,
  isTyping,
  isButtonDisabled,
  setMessage,
  message,
  fetchMessages,
  currentPageVersionsId,
  handleClearMessages,

}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const messagesContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const recognizerRef = useRef(null);

  useEffect(() => {
    if (fetchMessages) {
      fetchMessages();
    }
  }, [fetchMessages]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const handleFocus = () => {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    };

    const inputElement = inputRef.current;
    inputElement.addEventListener("focus", handleFocus);

    return () => {
      inputElement.removeEventListener("focus", handleFocus);
    };
  }, []);
  let lastRecognizedText = "";
  let interimResult = "";

  const toggleSpeechRecognition = () => {
    if (isTranscribing) {
      stopSpeechRecognition();
    } else {
      startSpeechRecognition();
    }
  };

  const startSpeechRecognition = () => {
    setIsTranscribing(true);
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      "b8465da9e9f74d96a224494e2f7bd84b",
      "eastus"
    );
    speechConfig.requestWordLevelTimestamps();
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new SpeechSDK.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    recognizer.recognizing = (s, e) => {
      if (e.result.reason === SpeechSDK.ResultReason.RecognizingSpeech) {
        interimResult = e.result.text;
        setMessage((prevMessage) => lastRecognizedText + interimResult);
      }
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        lastRecognizedText += e.result.text + " ";
        setMessage(lastRecognizedText);
        interimResult = "";
      }
    };

    recognizer.startContinuousRecognitionAsync(
      () => {
        // Recognition started successfully
      },
      (error) => {
        console.error(error);
        setIsTranscribing(false);
      }
    );

    // Stop recognition after 30 seconds (adjust as needed)
    setTimeout(() => {
      recognizer.stopContinuousRecognitionAsync(
        () => {
          setIsTranscribing(false);
        },
        (error) => {
          console.error(error);
          setIsTranscribing(false);
        }
      );
    }, 10000);
  };
  const stopSpeechRecognition = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync(
        () => {
          setIsTranscribing(false);
          recognizerRef.current = null;
        },
        (error) => {
          console.error(error);
          setIsTranscribing(false);
        }
      );
    } else {
      setIsTranscribing(false);
    }
  };
  const clearMessages = () => {
    handleClearMessages();
    setShowConfirmation(false);

  };

  return (
    <Flex direction="column" height="100%" position="relative">
      <Box position="absolute" right="1" zIndex="1">
        <IconButton
          icon={<MdDeleteSweep />}
          size="sm"
          variant="outline"
          colorScheme="whiteAlpha"
          _hover={{ bg: "whiteAlpha.600" }}
          aria-label="Clear chat"
    onClick={() => setShowConfirmation(true)}
        />
        {showConfirmation && (
          <HStack
            ml={2}
            bg="whiteAlpha.800"
            p={2}
            borderRadius="md"
            boxShadow="md"
          >
            <ChakraText  fontSize="sm">Clear all messages?</ChakraText>
            <Button size="xs" onClick={clearMessages}       color="#FF5F5F"
      variant="solid"
>
              Yes
            </Button>
            <Button size="xs" onClick={() => setShowConfirmation(false)}       colorScheme="gray"
      variant="outline"
>
              No
            </Button>
          </HStack>
        )}
      </Box>

      <Box flex="1" overflowY="auto" p={4} ref={messagesContainerRef}>
        <VStack spacing={4} align="stretch">
          {messages.map((msg, index) => (
            <MessageBubble
              key={index}
              message={msg.message}
              sender={msg.sender}
            />
          ))}
        </VStack>
      </Box>

      <HStack spacing={4} px={4} pb={4} pt={1}></HStack>

      <Flex className="message-input" pl={2} pr={2} pb={4} alignItems="center">
        <Box position="relative" width="100%">
          <Textarea
            ref={inputRef}
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" && !e.shiftKey && handleSendMessage(message)
            }
            size="sm"
            resize="none"
            minHeight="70px"
            overflow="auto"
            bg="white"
            pt={2}
            pr={3} // Reduced right padding
            mr={2}
            borderRadius="md"
          />
          {FEATURE_FLAGS.ENABLEMICROPHONE && (
            <IconButton
              icon={isTranscribing ? <Spinner size="sm" /> : <FaMicrophone />}
              position="absolute"
              right="2px"
              top="50%"
              transform="translateY(-50%)"
              size="sm"
              onClick={toggleSpeechRecognition}
              colorScheme={isTranscribing ? "secondary" : "primary"}
              borderRadius="full"
              zIndex={2}
              variant={isTranscribing ? "ghost" : "ghost"}
              p={0}
            />
          )}
        </Box>
        <Button
          ml={2}
          rightIcon={<AiOutlineSend />}
          colorScheme="blue"
          onClick={() => handleSendMessage(message)}
          px={6}
          borderRadius="md"
          isDisabled={isButtonDisabled || message.trim() === ""}
          isLoading={isTyping}
          loadingText={isTyping ? "Typing..." : "Generating"}
        >
          {isTyping ? <Spinner size="sm" /> : "Send"}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ChatComponent;
