export const PLANS = [
  {
    value: "free",
    price: "$0",
    name: "Free",
    tasks: "100 Tasks/mo",
  },
  {
    value: "starter",
    price: "$19.99",
    name: "Starter",
    tasks: "750 Tasks/mo",
  },
  {
    value: "professional",
    price: "$49",
    name: "Professional",
    tasks: "2000 Tasks/mo",
  },
];
