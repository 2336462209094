import { tracker } from "../Utilities/TrackerInstance";
import { supabase } from "../Auth/supabaseClient";

async function getUserPlanFeatures() {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  if (user) {
    const { data, error } = await supabase
      .from("plans")
      .select("features")
      .single();

    if (error) {
      console.error("Error fetching user plan:", error);
      return [];
    }
    console.log(data.features)
    return data.features || [];
  }
  return [];
}

export const FEATURE_FLAGS = {
  ENABLEMICROPHONE: false,
  ENABLESTYLEMANAGERPANEL: window.location.hostname !== "localhost",
  ENABLEIMPORT:false ,
  ENABLE_STATISTICS:
    window.location.hostname === "localhost" ,
};
tracker.onFlagsLoad(async (flags) => {
  console.log("Flags loaded:", flags);
  const userPlanFeatures = await getUserPlanFeatures();
  
  Object.keys(FEATURE_FLAGS).forEach((key) => {
    console.log("Checking flag:", key);
    console.log("Flag value before:", FEATURE_FLAGS[key]);
    const flag = flags.find((f) => f.key === key);
    const isInUserPlan = userPlanFeatures.includes(key);
    FEATURE_FLAGS[key] = (flag && flag.value) || isInUserPlan || FEATURE_FLAGS[key];
    console.log("Flag value after:", FEATURE_FLAGS[key]);
  });
});
