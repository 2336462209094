// theme/colors.js
export const colors = {
  primary: {
    50: "#e6f0ff",
    100: "#b3d1ff",
    200: "#80b3ff",
    300: "#4d94ff",
    400: "#1a75ff",
    500: "#3C8DFF", // Your new primary color
    600: "#0066ff",
    700: "#0052cc",
    800: "#003d99",
    900: "#002966",
  },
  secondary: {
    50: '#e6fff2',
    100: '#b3ffe0',
    200: '#80ffcd',
    300: '#4dffbb',
    400: '#1affa8',
    500: '#00FF7F', // Your main secondary color
    600: '#00cc66',
    700: '#00994d',
    800: '#006633',
    900: '#00331a',
  },

  brand: {
    50: "#f0f4f8", // Light
    100: "#d9e2ec",
    200: "#bcccdc",
    300: "#9fb3c8",
    400: "#829ab1",
    500: "#627d98", // Default
    600: "#486581",
    700: "#334e68",
    800: "#243b53",
    900: "#102a43", // Dark
  },
};
