/**
 * Extracts all head tags from the given HTML string, only including scripts with a src attribute.
 * Also removes the top-level tag wrapping the HTML content.
 * @param {string} htmlString - The HTML string to extract head tags from.
 * @returns {object} An object containing the extracted head tags and the cleaned HTML.
 */
export default function extractHeadTags(htmlString) {
  const headTags = ['meta', 'link', 'title', 'style', 'script', 'base'];
  let headContent = '';

  // Remove all head tags
  headTags.forEach(tagName => {
    const regex = new RegExp(`<${tagName}(\\s[^>]*)?>.*?<\\/${tagName}>`, 'gi');
    const selfClosingRegex = new RegExp(`<${tagName}[^>]*\\/?>`, 'gi');

    htmlString = htmlString.replace(regex, match => {
      if (tagName !== 'script' || /src\s*=/.test(match)) {
        headContent += match;
        return ''; // Remove the matched tag from the HTML
      }
      return match;
    });

    htmlString = htmlString.replace(selfClosingRegex, match => {
      if (tagName !== 'script' || /src\s*=/.test(match)) {
        headContent += match;
        return ''; // Remove the matched tag from the HTML
      }
      return match;
    });
  });

  // Remove the top-level tag wrapping the HTML content
  const topLevelTagRegex = /^<([a-zA-Z0-9]+)(\s[^>]*)?>/;
  const closingTopLevelTagRegex = /<\/([a-zA-Z0-9]+)>$/;
  
  const topLevelTagMatch = htmlString.match(topLevelTagRegex);
  const closingTopLevelTagMatch = htmlString.match(closingTopLevelTagRegex);
  
  if (topLevelTagMatch && closingTopLevelTagMatch && topLevelTagMatch[1] === closingTopLevelTagMatch[1]) {
    htmlString = htmlString.replace(topLevelTagRegex, ''); // Remove opening tag
    htmlString = htmlString.replace(closingTopLevelTagRegex, ''); // Remove closing tag
  }

  return {
    headTags: headContent,
    cleanedHtml: htmlString
  };
}
