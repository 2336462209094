// theme/components/menu.js
export const Menu = {
    parts: ['list', 'item', 'button'], // Parts of menu component you want to style
    baseStyle: {
      list: {
      },
      item: {
        color: 'brand.500',

        _hover: {
          bg: 'brand.700', // Darker on hover
          color: 'white', // White text on hover
        }
      },
      button: {
        colorScheme: 'whiteAlpha', // Use the whiteAlpha color scheme
        variant: 'ghost', // Ghost variant by default
        _hover: {
          bg: 'whiteAlpha.400', // Light background on hover
        }
      }
    },
    variants: {
      frostedGlass: {
        button: {
          bg: 'transparent',
          _hover: {
            bg: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(10px)',
          },
        }
      }
    }
  };
  
