/**
 * Pull all script tags from the given HTML string.
 * @param {string} htmlString - The HTML string to pull tags from.
 * @returns {Object} An object containing the HTML string without script tags and an array of script contents.
 */
export function extractScriptTagsFromHtml(htmlString) {
    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    let modifiedHtml = htmlString.replace(scriptRegex, () => {
        return ''; // Remove script tag from the HTML
    });

    return {
        htmlWithoutScripts: modifiedHtml
    };
}