import React, { useState, useCallback } from "react";
import { useSite } from "./SiteContext";
import {
  Box,
  HStack,
  Textarea,
  Button,
  Flex,
  Icon,
  useToast,
  Text,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { AiOutlineImport, AiOutlineExport } from "react-icons/ai";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectBody, selectCss, selectHead, selectJs } from 'store/selectors';

export default function ImportExport() {
  const [importCode, setImportCode] = useState("");
  const { pageId } = useSite();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidHtml, setIsValidHtml] = useState(false);

  const fetchedJs = useSelector(selectJs);
  const fetchedCss = useSelector(selectCss);
  const fetchedBody = useSelector(selectBody);
  const fetchedHead = useSelector(selectHead);

  const validateHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  const handleInputChange = (e) => {
    const newCode = e.target.value;
    setImportCode(newCode);
    setIsValidHtml(validateHtml(newCode));
  };

  const handleImport = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://vcngtqlymiludjjctdhi.supabase.co/functions/v1/proxy/webhook/import",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ html: importCode, pageId: pageId }),
        }
      );
      const result = await response.text();
      setIsLoading(false);

      if (result.trim().toLowerCase() === "ok") {
        toast({
          title: "HTML imported successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Import failed",
        description: "An error occurred while importing the HTML.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateHtmlContent = useCallback(() => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          ${fetchedHead}
          <style>${fetchedCss}</style>
        </head>
        <body>
          ${fetchedBody}
          <script>${fetchedJs}</script>
        </body>
      </html>
    `;
  }, [fetchedHead, fetchedCss, fetchedBody, fetchedJs]);

  const handleExport = () => {
    const htmlContent = generateHtmlContent();
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_page.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Box bg="gray.800" p={4} color="white">
      <Tabs>
        <TabList>
          <Tab>Import</Tab>
          <Tab>Export</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <HStack mb={4}>
              <AiOutlineImport size={24} />
              <Text fontSize="lg" fontWeight="bold">
                Import HTML
              </Text>
            </HStack>
            <Text fontSize="sm" mb={4} color="gray.300">
              Copy paste your HTML below to create a new version of the page with your HTML
            </Text>
            <Textarea
              value={importCode}
              onChange={handleInputChange}
              placeholder="Paste your HTML here"
              height="400px"
              color="white"
            />
            {importCode && (
              <Flex align="center" color={isValidHtml ? "green.500" : "red.500"}>
                <Icon as={isValidHtml ? FaCheck : FaTimes} mr={2} />
                <Text>{isValidHtml ? "HTML is valid" : "HTML is not valid"}</Text>
              </Flex>
            )}

            <Box display="flex" justifyContent="end" mt={4} gap={2}>
              <Button
                onClick={handleImport}
                isLoading={isLoading}
                loadingText="Importing..."
                isDisabled={importCode.trim() === "" || !isValidHtml}
              >
                {isLoading ? <Spinner size="sm" /> : "Import Code & Generate Version"}
              </Button>
            </Box>
          </TabPanel>
          <TabPanel>
            <HStack mb={4}>
              <AiOutlineExport size={24} />
              <Text fontSize="lg" fontWeight="bold">
                Export HTML
              </Text>
            </HStack>
            <Text fontSize="sm" mb={4} color="gray.300">
              Export the current page content as an HTML file
            </Text>
            <Box display="flex" justifyContent="start" mt={4} gap={2}>
              <Button onClick={handleExport}>
                Export HTML
              </Button>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}