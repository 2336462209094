// LoginPage.js
import React from 'react';
import { useToast, Link } from '@chakra-ui/react';
import { supabase, useAuth } from './supabaseClient';
import AuthForm from './AuthForm';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/routeConstants';
import { Link as RouterLink } from 'react-router-dom';

const LoginPage = () => {
const toast = useToast();
const navigate = useNavigate();
const { setProfileCompliting } = useAuth();

const handleLogin = async (event, email, password) => {
    event.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
        toast({
            title: "An error occurred.",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
        });
    } else {
        toast({
            title: "Login successful",
            description: "You are now logged in.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
        const account = await supabase
            .from("account")
            .select("onboarding_complete")
            .single();

        setProfileCompliting(() => account.data?.onboarding_complete);
        account.data?.onboarding_complete 
            ? navigate(ROUTE_PATHS.APP)
            : navigate(ROUTE_PATHS.COMPLETE_PROFILE);
    }
};

const links = (
    <>
            <Link as={RouterLink} variant="ghost" colorScheme="brand" to={ROUTE_PATHS.FORGOT_PASSWORD}>Forgot password?</Link>
            <Link as={RouterLink} variant="ghost" colorScheme="brand" to={ROUTE_PATHS.SIGNUP}>Sign Up</Link>
    </>
);

return <AuthForm heading="Sign in to your account" buttonLabel="Login" onFormSubmit={handleLogin} links={links} />;
};

export default LoginPage;

