// Header.js
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Select,
  Button,
  IconButton,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { useSite } from '../Site/SiteContext';
import { ROUTE_PATHS } from 'constants/routeConstants';





const Header = () => {
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        versions,
        siteName,
        currentVersion,
        setCurrentVersion,
        currentUUID,
        setcurrentPageVersionsId
    } = useSite();

    const handleVersionChange = (e) => {
        const selectedVersionLabel = e.target.value;
        const selectedVersion = versions.find(version => version.label === selectedVersionLabel);
    
        if (selectedVersion) {
            setCurrentVersion(selectedVersionLabel);
            setcurrentPageVersionsId(selectedVersion.SiteVersionsId);
        } else {
            console.error("Selected version not found in versions array.");
        }        if (!isLargerThan768) {
            onClose(); // Close the menu on selection
        }

    };

    const handlePreviewClick = () => {
        const baseUrl = window.location.origin;
        const path = `${baseUrl}/preview/${currentUUID}`;
        window.open(path, '_blank');
    };


    return (
        <Box bg="brand.800" p={{ base: 2, md: 4 }} color="white" flexShrink="0">
            <Flex justifyContent="space-between" alignItems="center">
                <Link to={ROUTE_PATHS.APP}>
                    <Button size='sm' colorScheme="whiteAlpha" variant="outline">
                        Back to All Sites
                    </Button>
                </Link>
                <Heading as="h1" size="md" m={{ base: 2, md: 0 }} textAlign="center" flexGrow={1}>
 {siteName}                </Heading>
                {isLargerThan768 ? (
                    <Flex alignItems="center">
                        <Text marginRight={2}>Version:</Text>
                        <Select
                            placeholder="Select version"
                            value={currentVersion}
                            onChange={handleVersionChange}
                            bg="white"
                            color="gray.800"
                            borderColor="gray.300"
                            height="30px"
                            width="100px"
                            marginRight={4}
                        >
                            {versions.map((version) => (
                                <option key={version.id} value={version.label}>{version.label}</option>
                            ))}
                        </Select>
                        <Button
                            colorScheme="teal"
                            variant="solid"
                            onClick={handlePreviewClick}
                        >
                            Preview
                        </Button>
                    </Flex>
                ) : (
                    <Menu isOpen={isOpen} onClose={onClose}>
                        <MenuButton
                            as={IconButton}
                            icon={<HamburgerIcon />}
                            variant="outline"
                            colorScheme="whiteAlpha"
                            onClick={onOpen}
                        />
                        <MenuList>
                            {versions.map((version) => (
                                <MenuItem key={version.id} onClick={() => handleVersionChange({ target: { value: version.label }})}>
                                    {version.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                )}
            </Flex>
        </Box>
    );
};

export default Header;
