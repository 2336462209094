/**
 * Merges the given head HTML and body HTML into a single HTML string, storing all tags in the body.
 * @param {string} headHtml - The HTML string containing the head content.
 * @param {string} bodyHtml - The HTML string containing the body content.
 * @returns {string} The combined HTML string with the head and body content.
 */
export default function mergeHeadAndBody(headHtml, bodyHtml) {
  
  const combinedDoc = 
  `<!DOCTYPE html>
    <html>
      <head>${headHtml}</head>
      <body>${bodyHtml}</body>  
    </html>`;
  
    return combinedDoc;
}