import { createSlice } from '@reduxjs/toolkit';

// Define the initial state type
/**
 * @typedef {Object} EditorState
 * @property {string|null} command
 * @property {string} css
 * @property {string} js
 * @property {string} body
 * @property {string} head
 * @property {boolean} isLoading
 * @property {boolean} templateRenderTrigger
 * @property {number} selectedVersionInhistoryPage
 * @property {boolean} isUnsavedVersionViewing
 */

/** @type {EditorState} */
const initialState = {
  command: null,
  css: '',
  js: '',
  body: '',
  head: '',
  isLoading: false,
  templateRenderTrigger: false,
  selectedVersionInhistoryPage: null,
  isUnsavedVersionViewing: false
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    /** 
     * @param {EditorState} state 
     */
    triggerUndo: (state) => {
      state.command = 'undo';
    },
    
    /** 
     * @param {EditorState} state 
     */
    triggerRedo: (state) => {
      state.command = 'redo';
    },
    
    /** 
     * @param {EditorState} state 
     */
    clearCommand: (state) => {
      state.command = null;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action
     */
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setJs: (state, action) => {
      state.js = action.payload;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setCss: (state, action) => {
      state.css = action.payload;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setBody: (state, action) => {
      state.body = action.payload;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setHead: (state, action) => {
      state.head = action.payload;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setIsUnsavedVersionViewing: (state, action) => {
      state.isUnsavedVersionViewing = action.payload;
    },
    
    /**
     * @param {EditorState} state 
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    updateSelectedVersionInhistoryPage: (state, action) => {
      state.selectedVersionInhistoryPage = action.payload;
    },
    
    /** 
     * @param {EditorState} state 
     */
    templateRenderTrigger: (state) => {
      state.templateRenderTrigger = !state.templateRenderTrigger;
    },
    
    /** 
     * @param {EditorState} state 
     */
    clearAll: (state) => {
      state.css = '';
      state.body = '';
      state.head = '';
    },
  },
});

export const {
  triggerUndo,
  triggerRedo,
  clearCommand,
  setLoading,
  setCss,
  setJs,
  setBody,
  setHead,
  clearAll,
  updateSelectedVersionInhistoryPage,
  setIsUnsavedVersionViewing
} = editorSlice.actions;

export default editorSlice.reducer;