// RadioCard.js
import { useRadio } from "@chakra-ui/react";
import { Box, Flex } from "@chakra-ui/react";
import React from "react";

export const RadioCard = ({ icon: Icon, children, isDisabled, ...rest }) => {
  const { getInputProps, getCheckboxProps } = useRadio({ ...rest, isDisabled });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" height="150px">
      <input {...input} disabled={isDisabled} />
      <Box
        {...checkbox}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        textTransform="capitalize"
        _checked={{
          bg: "primary.500",
          color: "white",
          borderColor: "primary.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        _hover={{
          cursor: isDisabled ? "not-allowed" : "pointer",
          bg: isDisabled ? "inherit" : "rgba(255, 255, 255, 0.3)",
          backdropFilter: "blur(10px)",
          color: 'black',
          
        }}
        px={8}
        py={4}
        opacity={isDisabled ? 0.6 : 1}
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          sx={{
            flexDir: "column",
            alignItems: "center",
            textAlign: "center",
            
          }}
          
        >
          {Icon && (
            <Box sx={{ p: 4 }}>
              <Icon size="40px" />
            </Box>
          )}
          {children}
        </Flex>
      </Box>
    </Box>
  );
};
