// theme/index.js
import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { styles } from "./styles";
import { Button } from "./components/button";
import { Menu } from "./components/menu";
import { StepsTheme as Steps } from "chakra-ui-steps";

export const theme = extendTheme({
  colors: {
    primary: colors.primary,
    secondary: colors.secondary,
    brand: colors.brand,
  },

  styles,
  components: {
    Button,
    Steps,
    Menu,
    Heading: {
      baseStyle: {
        fontWeight: 700,
      },}

  },
  fonts: {
    body: "'Nunito Sans', sans-serif",
    heading: "Poppins, sans-serif",
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
});
