export const ROUTE_PATHS = {
    TEST: '/test',
    LOGIN: '/login',
    SIGNUP: '/signup',
    FARGOT_PASSWORD: '/fargot-password',
    HOME: '/',
    COMPLETE_PROFILE: '/complete-profile',
    PREVIEW: '/preview/:uuid',
    SITE_WITH_ID: '/site/:id',
    SITE_WITH_PAGE_NAME: '/site/:id/:pageName',
    APP: '/sites',
    SETTINGS: '/settings',
  };