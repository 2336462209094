const loadingTexts = [
    "Warming up the hamsters...",
    "Generating witty dialog...",
    "Swapping time and space...",
    "Spinning violently around the y-axis...",
    "Bending the spoon...wait, there is no spoon...",
    "We're testing your patience...",
    "As if you had a choice...",
    "Don't think of purple hippos...",
    "We're making you a cookie, but we ate it...",
    "Creating time-loop inversion field...",
    "Spinning the wheel of fortune...",
    "Loading the enchanted bunny...",
    "Computing chance of success...",
    "I swear it's almost done...",
    "Let's take a minute to reflect...",
    "Grabbing the universe by the scruff...",
    "We need a bigger boat...",
    "Looking for the power button...",
    "Are we there yet?",
    "Just count to 10..."
  ];
  
  export default loadingTexts;