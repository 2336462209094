// HomePage.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function HomePage() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Text fontSize="2xl">Welcome to the AI Page Generator</Text>
    </Box>
  );
}

export default HomePage;
