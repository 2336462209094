import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Stack,
  VStack,
  Flex,
  Heading,
  useRadioGroup,
  useColorModeValue,
  RadioGroup,
  Slide,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import OnboardingChatInterface from "./OnboardingChatInterface";
import {
  FaLaptop,
  FaShoppingCart,
  FaMobileAlt,
  FaBullhorn,
  FaListAlt,
} from "react-icons/fa";
import { RadioCard } from "./RadioCard";
import { supabase } from "../Auth/supabaseClient";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";

const steps = [
  {
    label: "Step 1",
    description: "Choose the type of site you want to create",
  },
  { label: "Step 2", description: "Provide details about your site" },
];

const siteOptions = [
  { label: "Single Page", value: "landing", icon: FaLaptop },
  {
    label: "Website",
    value: "marketing",
    icon: FaBullhorn,
    isDisabled: true,
    comingSoon: true,
  },
  {
    label: "E-shop",
    value: "eshop",
    icon: FaShoppingCart,
    isDisabled: true,
    comingSoon: true,
  },
  {
    label: "Web App",
    value: "app",
    icon: FaMobileAlt,
    isDisabled: true,
    comingSoon: true,
  },
];

const OnboardingSteps = ({
  onSiteCreated,
  variant,
  onBack,
  siteId,
  setSiteId,
  currentPageVersionsId,
  setcurrentPageVersionsId,
  initialStep = 0,
}) => {
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: initialStep,
  });
  const [currentSection, setCurrentSection] = useState("Chat");
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [siteType, setSiteType] = useState("landing");
  const [siteCreated, setSiteCreated] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "siteType",
    value: siteType,
    onChange: setSiteType,
  });

  const group = getRootProps();

  const handleGenerateSite = async () => {
    setIsGenerating(true);
    const message = "Generate new version";
    await supabase.functions.invoke("new-version", {
      body: JSON.stringify({ message, siteId }),
    });
    navigate(`/site/${siteId}`);
  };

  const fetchParameters = useCallback(async () => {
    if (!siteId) return;

    try {
      const { data, error } = await supabase
        .from("parameters")
        .select("id, parameter_name, parameter_value")
        .eq("site_id", siteId);

      if (error) {
        throw error;
      }

      setParameters(data);
    } catch (error) {
      console.error("Error fetching parameters:", error.message);
    }
  }, [siteId]);

  const handleButtonClick = (section) => {
    setCurrentSection(section);
  };

  useEffect(() => {
    fetchParameters();

    const subscription = supabase
      .channel(`public:parameters:site_id=eq.${siteId}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "parameters",
          filter: `site_id=eq.${siteId}`,
        },
        (payload) => {
          setParameters((prev) => [...prev, payload.new]);
        }
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "parameters",
          filter: `site_id=eq.${siteId}`,
        },
        (payload) => {
          setParameters((prev) =>
            prev.map((param) =>
              param.id === payload.new.id ? payload.new : param
            )
          );
        }
      )
      .on(
        "postgres_changes",
        {
          event: "DELETE",
          schema: "public",
          table: "parameters",
          filter: `site_id=eq.${siteId}`,
        },
        (payload) => {
          setParameters((prev) =>
            prev.filter((param) => param.id !== payload.old.id)
          );
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [siteId, fetchParameters]);

  const hasCompletedAllSteps = activeStep === steps.length;
  const completedBg = useColorModeValue("green.50", "green.800");

  const renderStepContent = (index) => {
    switch (index) {
      case 0:
        return (
          <VStack spacing={4} align="stretch">
            <Heading size="lg" textAlign="center">
              What type of site do you want to create?
            </Heading>
            <RadioGroup {...group}>
              <Stack
                spacing={2}
                direction={["column", "row"]}
                wrap="wrap"
                justifyContent="center"
              >
                {siteOptions.map(
                  ({ value, label, icon, isDisabled, comingSoon }) => {
                    const radio = getRadioProps({ value, isDisabled });
                    return (
                      <RadioCard
                        key={value}
                        {...radio}
                        icon={icon}
                        isDisabled={isDisabled}
                      >
                        <Box textAlign="center">
                          {label}
                          {comingSoon && (
                            <Text fontSize="sm" color="gray.500">
                              Coming Soon
                            </Text>
                          )}
                        </Box>
                      </RadioCard>
                    );
                  }
                )}
              </Stack>
            </RadioGroup>
            <Flex justify="space-between">
              <Button onClick={onBack} size="lg" variant="outline">
                Back
              </Button>
              <Button onClick={nextStep} size="lg">
                Next
              </Button>
            </Flex>
          </VStack>
        );
      case 1:
        return (
          <VStack spacing={2} align="stretch" height="75vh">
            {!isMobile && (
              <Heading size="lg" textAlign="center">
                Tell us what your site should be about
              </Heading>
            )}
            <Flex direction={isMobile ? "column" : "row"} height="100%">
              <Box
                flex="1"
                overflow="hidden"
                bg="brand.800"
                p={4}
                borderRadius="lg"
                boxShadow="xl"
              >
                <OnboardingChatInterface
                  siteCreated={siteCreated}
                  setSiteCreated={setSiteCreated}
                  siteId={siteId}
                  setSiteId={setSiteId}
                  currentPageVersionsId={currentPageVersionsId}
                  setcurrentPageVersionsId={setcurrentPageVersionsId}
                  onGenerateSite={handleGenerateSite}
                />
              </Box>
              {!isMobile && (
                <Box
                  flex="0.5"
                  overflow="hidden"
                  bg="brand.50"
                  p={4}
                  borderRadius="lg"
                  boxShadow="xl"
                  ml={4}
                >
                  <Heading size="md" mb={4} fontFamily="heading">
                    Website Profile
                  </Heading>
                  {parameters.length > 0 ? (
                    parameters.map((param, index) => (
                      <Box key={index} mb={2}>
                        <Text fontWeight="bold">{param.parameter_name}</Text>
                        <Text>{param.parameter_value}</Text>
                      </Box>
                    ))
                  ) : (
                    <Text>No parameters found.</Text>
                  )}
                </Box>
              )}
            </Flex>
            {isMobile && (
              <>
                <Slide
                  direction="right"
                  in={currentSection === "Parameters"}
                  style={{ zIndex: 10 }}
                >
                  <Box
                    position="fixed"
                    right={0}
                    top={0}
                    height="100%"
                    width="80%"
                    bg="brand.50"
                    p={4}
                    boxShadow="xl"
                  >
                    <Heading size="md" mb={4} fontFamily="heading">
                      Website Profile
                    </Heading>
                    {parameters.length > 0 ? (
                      parameters.map((param, index) => (
                        <Box key={index} mb={2}>
                          <Text fontWeight="bold">{param.parameter_name}</Text>
                          <Text>{param.parameter_value}</Text>
                        </Box>
                      ))
                    ) : (
                      <Text>No parameters found.</Text>
                    )}
                  </Box>
                </Slide>
                {isMobile && (
                  <Box position="relative" height="40px">
                    {" "}
                    {/* Adjust height as needed */}
                    <IconButton
                      icon={<FaListAlt />}
                      variant="frostedGlass"
                      position="absolute"
                      bottom="2"
                      right="2"
                      size="lg"
                      onClick={() =>
                        handleButtonClick(
                          currentSection === "Chat" ? "Parameters" : "Chat"
                        )
                      }
                      zIndex="overlay"
                    />
                  </Box>
                )}
              </>
            )}
        <Flex justify="space-between" mt={4}>
    <Button onClick={prevStep} size="lg" variant="outline">
      Back
    </Button>
    <Button onClick={handleGenerateSite} size="lg" variant="primary" isDisabled={parameters.length === 0 || isGenerating} isLoading={isGenerating}>
      Generate Site
    </Button>
  </Flex>

          </VStack>
        );

      default:
        return null;
    }
  };

  return (
    <Flex
    flexDir="column"
    width="100%"
    p={[0, 4]}
    pt={[2, 4]}
    pb={0}
    alignItems="center"
    justifyContent="flex-start"
  

    >
      {isMobile ? (
        <>
          <Box textAlign="center" mb={4}>
            <Heading size="md">{steps[activeStep].label}</Heading>
            <Text>{steps[activeStep].description}</Text>
          </Box>
          <Box p={[1, 4]} pt={0} pb={0} my={1} rounded="md" width="100%">
            {renderStepContent(activeStep)}
          </Box>
        </>
      ) : (
        <Steps
        size="sm"
        colorScheme="secondary"
        activeStep={activeStep}
        orientation="horizontal"
        variant={variant}
        width="100%"
        flexGrow={1}
            >
          {steps.map(({ label, description }, index) => (
            <Step label={label} key={label} description={description}>
<Box p={[1, 4]} pt={0} pb={0} my={1} rounded="md" width="100%" flexGrow={1}>
                {renderStepContent(index)}
              </Box>
            </Step>
          ))}
        </Steps>
      )}
      {hasCompletedAllSteps && (
        <Box
          bg={completedBg}
          my={8}
          p={8}
          rounded="md"
          maxWidth="800px"
          width="100%"
        >
          <Heading fontSize="xl" textAlign="center">
            Congratulations! Your site has been created.
          </Heading>
        </Box>
      )}
    </Flex>
  );
};

export default OnboardingSteps;
