import React, { useState, useRef, useEffect } from 'react';
import { Box, Flex, Textarea, Button } from '@chakra-ui/react';
import { usePopper } from 'react-popper';
import { useSite } from '../../SiteContext';
import { getDistanceToWindowBorders } from '../getDistanceToWindowBorders'

const DEFAULT_POOPER_CONFIG = {
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 0],
      },
    },
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
      },
    },
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false, // Use the CSS top, left properties instead of transform for positioning
      },
    },
  ],
};

/**
 * FloatingMenu component
 * @param {Object} props - Component props
 * @param {string} props.selectedComponentId - ID of the selected component
 * @param {Object} props.mousePosition - Position of the mouse
 * @param {function} props.onClose - Function to handle closing the menu
 */
export const FloatingMenu = ({ selectedComponentId, mousePosition, onClose }) => {
  const inputRef = useRef();
  const { performChange } = useSite();
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(null, popperElement, DEFAULT_POOPER_CONFIG);

  /**
   * Updates the position of the popper element based on mouse position
   */
  useEffect(() => {
    if (mousePosition && popperElement) {
      const isFitToBorder = getDistanceToWindowBorders(null, mousePosition.x, 300).right > 0;
      const left = `${isFitToBorder ? mousePosition.x : mousePosition.x - 150}px`

      popperElement.style.position = 'fixed';
      popperElement.style.top = `${mousePosition.y}px`;
      popperElement.style.left = left;
    }
  }, [popperElement]);

  /**
   * Handles clicking outside of the popper element to close the menu
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperElement && !popperElement.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperElement, onClose]);

  /**
   * Handles the action when the submit button is clicked
   */
  const handleAction = () => {
    const request = inputRef.current ? inputRef.current.value.trim() : '';
    const elementId = selectedComponentId || null;

    if (request) {
      performChange(null, request, elementId);
      inputRef.current.value = '';
    }
    onClose();
  };

  return (
    <Box ref={setPopperElement} style={styles.popper} {...attributes.popper} className="floating-menu" zIndex={1000}>
      <Flex className="input-popup" width="300px">
        <Textarea
          borderRightRadius="0"
          height="content"
          ref={inputRef}
          placeholder="Describe change..."
          className="request-input"
          resize="none"
          minHeight="40px"
          overflow="hidden"
          onInput={(e) => {
            if (!e) return;

            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
        />
        <Button 
          height="content" 
          borderLeftRadius="0"  
          onClick={handleAction} 
          className="submit-button">
          Submit
        </Button>
      </Flex>
    </Box>
  );
};
