import { supabase } from 'Auth/supabaseClient';
import { MURKUP_REQUEST } from './constants/murkupRequestConstant';

/**
 * Moves a page version to the top of the list without removing it from its current position.
 * @param {string} SiteVersionsId - The ID of the version to move to the top.
 */
async function moveVersionToTop(SiteVersionsId, siteId, pageId) {
  const ERROR_FETCHING_VERSIONS = 'Error fetching current versions:';
  const ERROR_VERSION_NOT_FOUND = `Version with id ${SiteVersionsId} not found.`;
  const ERROR_DUPLICATING_VERSION = 'Error duplicating version:';
  const UNEXPECTED_ERROR_MOVING_VERSION = 'Unexpected error moving version:';

  try {
    const { data: currentVersions, error: fetchError } = await supabase
        .from('page_versions')
        .select(`html, ${MURKUP_REQUEST}, id, version, change_description, version`)
        .eq('site_id', siteId)
        .eq('page_id', pageId)
        .order('version', { ascending: false });

    if (fetchError) {
      console.error(ERROR_FETCHING_VERSIONS, fetchError);
      return;
    }

    // Find index of the version to move within current versions
    const currentIndex = currentVersions.findIndex(version => version.id === SiteVersionsId);

    if (currentIndex === -1) {
      console.error(ERROR_VERSION_NOT_FOUND);
      return;
    }

    const {html, head, body, css, js, version} = currentVersions[currentIndex];
    const latestVersion = Math.max(...currentVersions.map(v => v.version));
const newVersion = latestVersion + 1;




    // here i am duplicating the version and add it to the beginning of the list
    const { error: duplicateError } = await supabase
      .from('page_versions')
      .insert({
        head, 
        body, 
        html,
        css, 
        js,
        version: newVersion,
        change_description: 'Restored version ' + version ,
        page_id: pageId,
        site_id: siteId
      });
    
    
    
    
    if (duplicateError) {
      console.error(ERROR_DUPLICATING_VERSION, duplicateError);
      return;
    }

    console.log(`Version ${SiteVersionsId} moved to the top successfully.`);
  } catch (error) {
    console.error(UNEXPECTED_ERROR_MOVING_VERSION, error);
  }
}

export default moveVersionToTop;
