export const STEPS = [
  {
    title: "How shall we call you?",
    name: "first_name",
    placeholder: "Enter your first name",
    required: true,
  },
  {
    title: "Now what's your last name?",
    name: "last_name",
    placeholder: "Enter your last name",
    required: true,
  },

  {
    title: "Let's name your account? ",
    name: "company_name",
    placeholder: "Enter Account name",
    required: false,
  },
  {
    title: "Let's set you up",
    name: "plan",
    placeholder: "",
    required: true,
  },
];
