import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import OnboardingSteps from './OnboardingSteps';
import React, { useState, useEffect } from 'react';

const AddSiteModal = ({ isOpen, onClose, initialSiteVersionId='', onSiteAdded, initialStep = 0, initialSiteId = '' }) => {
  const toast = useToast();
  const [siteId, setSiteId] = useState(initialSiteId);
  const [currentPageVersionsId, setcurrentPageVersionsId] = useState(initialSiteVersionId);

  useEffect(() => {
    setSiteId(initialSiteId);
    setcurrentPageVersionsId(initialSiteVersionId);
  }, [initialSiteId,initialSiteVersionId]);

  const handleSiteCreated = (newSite) => {
    onSiteAdded(newSite);
    onClose();
    toast({
      title: 'Site Created',
      description: `Site "${newSite.name}" has been created successfully.`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <OnboardingSteps
            onSiteCreated={handleSiteCreated}
            variant="simple"
            siteId={siteId}
            setSiteId={setSiteId}
            currentPageVersionsId={currentPageVersionsId}
            setcurrentPageVersionsId={setcurrentPageVersionsId}
            onBack={onClose}
            initialStep={initialStep}  // Pass the initialStep prop
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddSiteModal;
