import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Tree } from '@minoru/react-dnd-treeview';
import { supabase } from '../Auth/supabaseClient';
import { useSite } from './SiteContext';
import { AiOutlineCopy, AiFillFileText, AiOutlineDelete,  AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Pages = () => {
  const [pages, setPages] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { siteId } = useSite();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [newPageName, setNewPageName] = useState('');
  const [pageToDelete, setPageToDelete] = useState(null);
  const [pageToClone, setPageToClone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPages = async () => {
      const { data, error } = await supabase
        .from('page')
        .select('id, name, parent_id, ranking')
        .eq('site_id', siteId)
        .order('ranking', { ascending: true });

      if (error) {
        console.error('Error fetching pages:', error);
        return;
      }

      const pagesById = {};
      const treeData = [];

      data.forEach((page) => {
        const node = { id: page.id, parent: page.parent_id || 0, droppable: false, text: page.name };
        pagesById[page.id] = node;
      });

      Object.values(pagesById).forEach(node => {
        if (node.parent && pagesById[node.parent]) {
          if (!pagesById[node.parent].children) {
            pagesById[node.parent].children = [];
          }
          pagesById[node.parent].children.push(node);
        } else {
          treeData.push(node);
        }
      });

      setPages(treeData);
      setFilteredPages(treeData);
    };

    fetchPages();
  }, [siteId]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredPages(pages);
    } else {
      const filtered = pages.filter((page) =>
        page.text.toLowerCase().includes(searchTerm)
      );
      setFilteredPages(filtered);
    }
  };

  const renderNode = (node) => (
    <HStack
      style={{ paddingLeft: node.depth * 20, display: 'flex', alignItems: 'center', width: '100%' }}
      spacing={2}
      className="tree-node"
    >
      <AiFillFileText />
      <Button
        variant="link"
        color="teal"
        onClick={() => navigate(`/site/${siteId}/${node.text}`)}
        style={{ flexGrow: 1, width: 'content', height: '100%' }}>
        <Text style={{ textOverflow: 'ellipsis', width: '80%', overflow: 'hidden' }}>
          {node.text}
        </Text>
      </Button>
      <IconButton
        icon={<AiOutlineCopy />}
        style={{ minWidth: 'fit-content' }}
        size="sm"
        variant="ghost"
        aria-label="Duplicate page"
        onClick={() => {
          setPageToClone(node);
          setNewPageName(`${node.text} - Copy`);
          onOpen();
        }}
      />
      <IconButton
        icon={<AiOutlineDelete />}
        size="sm"
        variant="ghost"
        aria-label="Delete page"
        onClick={() => {
          setPageToDelete(node);
          onDeleteOpen();
        }}
        isDisabled={pages.length === 1}
      />
    </HStack>
  );
    const ERROR_TITLE = "Error";
    const SUCCESS_TITLE = "Success";
    const TOAST_DURATION = 9000;
    const EMPTY_PAGE_NAME_ERROR = "Page name cannot be empty.";
    const CREATE_PAGE_ERROR = "There was an error creating the page.";
    const CREATE_PAGE_SUCCESS = "Page created successfully.";
    const UNEXPECTED_RESPONSE_ERROR = "Unexpected response format.";

    const handleCreatePage = async () => {
      if (!newPageName.trim()) {
      showToast(ERROR_TITLE, EMPTY_PAGE_NAME_ERROR, "error");
      return;
    }

    setIsLoading(true);

    const { data, error } = await supabase.functions.invoke('pages', {
      body: JSON.stringify({
        action: pageToClone ? 'duplicate' : 'create',
        siteId,
        name: newPageName,
        pageId: pageToClone?.id || null
      })
    });

    setIsLoading(false);

    if (error) {
      showToast(ERROR_TITLE, CREATE_PAGE_ERROR, "error");
      console.error('Error creating page:', error);
      return;
    }

    const newPageData = data?.data?.[0];
    if (newPageData?.id) {
      showToast(SUCCESS_TITLE, CREATE_PAGE_SUCCESS, "success");
      resetAndNavigate(newPageData);
    } else {
      showToast(ERROR_TITLE, UNEXPECTED_RESPONSE_ERROR, "error");
      console.error('Unexpected response format:', data);
    }
  };

  const showToast = (title, description, status) => {
    toast({ title, description, status, duration: TOAST_DURATION, isClosable: true });
  };

  const resetAndNavigate = (newPageData) => {
    setNewPageName('');
    onClose();
    const newPage = { id: newPageData.id, parent: 0, droppable: false, text: newPageName };
    setPages(prevPages => [...prevPages, newPage]);
    setFilteredPages(prevPages => [...prevPages, newPage]);
    setPageToClone(null);
    navigate(`/site/${siteId}/${newPageData.name}`);
  };

  const handleDeletePage = async () => {
    if (!pageToDelete) return;

    setIsLoading(true);

    const { error } = await supabase.functions.invoke('pages', {
      body: JSON.stringify({
        action: 'delete',
        pageId: pageToDelete.id
      })
    });

    setIsLoading(false);

    if (error) {
      toast({
        title: "Error",
        description: "There was an error deleting the page.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error('Error deleting page:', error);
    } else {
      toast({
        title: "Success",
        description: "Page deleted successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setPages(prevPages => prevPages.filter(page => page.id !== pageToDelete.id));
      setFilteredPages(prevPages => prevPages.filter(page => page.id !== pageToDelete.id));
      onDeleteClose();
    }
  };

  return (
    <Box width="100%" mx="auto" mt={4} border="1px" borderColor="gray.200" borderRadius="md" p={4} bg="white" boxShadow="md">
      <HStack justifyContent="space-between" mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<AiOutlineSearch />} />
          <Input
            placeholder="Search pages"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <IconButton
          icon={<AiOutlinePlus />}
          size="sm"
          onClick={onOpen}
          aria-label="Add new page"
        />
      </HStack>
      <Box height={600} overflowY="auto">
        <Tree
          tree={filteredPages}
          rootId={0}
          render={renderNode}
        />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{pageToClone ? 'Clone Page' : 'Create a new page'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Page name</FormLabel>
              <Input value={newPageName} onChange={(e) => setNewPageName(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreatePage} isLoading={isLoading}>
              {pageToClone ? 'Clone' : 'Create'}
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this page?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeletePage} isLoading={isLoading}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onDeleteClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Pages;
