import { FEATURE_FLAGS } from "constants/featureFlags";

const disableEditable = FEATURE_FLAGS.ENABLESTYLEMANAGERPANEL
  ? {
      panels: { defaults: [] },
      blockManager: { appendTo: null },
      styleManager: { appendTo: null },
      layerManager: { appendTo: null },
      traitManager: { appendTo: null },
    }
  : {};

export const editorConfig = {
  fromElement: true,
  allowScripts: 1,
  storageManager: {
    id: "gjs-",
    type: "local",
    autosave: true,
    autoload: true,
    stepsBeforeSave: 1,
  },
  canvasCss: `
    .gjs-selected {
        outline: 2px solid #3C8DFF !important;
        border-radius: 3px;
    }
    `,
  ...disableEditable,
};
