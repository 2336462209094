import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { supabase } from '../Auth/supabaseClient'; 
import { selectBody, selectCss, selectHead, selectJs } from 'store/selectors';

const PreviewPage = ({ id }) => {
  const [content, setContent] = useState('');
  const { uuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isInPreviewMode, setIsInPreviewMode] = useState(false);

  const fetchedJs = useSelector(selectJs);
  const fetchedCss = useSelector(selectCss);
  const fetchedBody = useSelector(selectBody);
  const fetchedHead = useSelector(selectHead);

  const generateHtmlContent = useCallback(() => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          ${fetchedHead}
          <style>${fetchedCss}</style>
        </head>
        <body>
          ${fetchedBody}
          <script>${fetchedJs}</script>
        </body>
      </html>
    `;
  }, [fetchedHead, fetchedCss, fetchedBody, fetchedJs]);

  const fetchSiteContent = useCallback(async (userId) => {
    let { data, error } = await supabase
      .from('page_versions')
      .select('html')
      .eq('uuid', userId)
      .single();

    if (error) {
      console.error("Error fetching site content:", error);
    } else {
      setContent(data.html);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsInPreviewMode(window.location.pathname.includes("/preview/"));
  }, []);

  useEffect(() => {
    if (isInPreviewMode) return;

    setContent(generateHtmlContent());
    setIsLoading(false);
  }, [id, generateHtmlContent, isInPreviewMode]);

  useEffect(() => {
    document.title = isLoading ? "Loading..." : "Live Preview";
  }, [isLoading]);

  useEffect(() => {
    if (!isInPreviewMode) return;

    const userId = uuid || id;

    if (userId) { // Make sure uuid is not undefined or null
      fetchSiteContent(userId);
    } else {
      console.log('No uuid provided, using siteId instead');
    }
  }, [uuid, id, isInPreviewMode, fetchSiteContent]);

  return (
    <Box bg="white" height="100vh">
      {isLoading ? (
        <Flex direction="column" justify="center" align="center" height="100%">
          <Spinner size="xl" color="blue.500" />
          <Text mt={4} fontSize="lg" color="gray.500">Loading Preview...</Text>
        </Flex>
      ) : (
        <iframe
          srcDoc={content}
          title="Preview"
          width="100%"
          height="100%"
          style={{ border: "none", overflow: 'auto' }}
        />
      )}
    </Box>
  );
};

export default PreviewPage;
