import React, { useState, useEffect } from "react";
import ChatComponent from "../Utilities/ChatComponent";
import { supabase } from "../Auth/supabaseClient";
import { useSite } from "./SiteContext";

function ChatInterface({ onSendMessage }) {
  const {
    handleSendMessage,
    isGenerating,
    messages,
    setMessages,
    currentPageVersionsId,
  } = useSite();
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (currentPageVersionsId) {
      const fetchMessages = async () => {
        const { data, error } = await supabase
          .from("ai_messages")
          .select("*")
          .filter("version", "eq", currentPageVersionsId)
          .order("created_at", { ascending: true });

        if (data.length > 2) {
          const lastMessage = data[data.length - 1];
          if (
            lastMessage.from_user === false &&
            lastMessage.is_typing === true
          ) {
            setIsTyping(true);
          }
        }
        if (error) {
          console.error("Error fetching messages", error);
        } else {
          setMessages(
            data.map((msg) => ({
              ...msg,
              sender: msg.from_user ? "User" : "Assistant",
            }))
          );
        }
      };

      fetchMessages();

      const messageSubscription = supabase
        .channel("realtime:public:ai_messages")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "ai_messages",
            filter: `version=eq.${currentPageVersionsId}`,
          },
          (payload) => {
            if (
              payload.new.version === currentPageVersionsId &&
              payload.new.from_user
            ) {
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  ...payload.new,
                  sender: payload.new.from_user ? "User" : "Assistant",
                },
              ]);
            }
            if (payload.new.from_user === false) {
              setIsTyping(payload.new.is_typing);
            }
          }
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "ai_messages",
            filter: `version=eq.${currentPageVersionsId}`,
          },
          (payload) => {
            setIsTyping(payload.new.is_typing);
            if (
              payload.new.version === currentPageVersionsId &&
              payload.new.from_user === false &&
              payload.old.is_typing === true &&
              payload.new.is_typing === false
            ) {
              setMessages((prevMessages) => {
                const messagesExceptLast = prevMessages.slice(
                  0,
                  prevMessages.length - 1
                );
                return [
                  ...messagesExceptLast,
                  {
                    ...payload.new,
                    sender: payload.new.from_user ? "User" : "Assistant",
                  },
                ];
              });
            }
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(messageSubscription);
      };
    }
  }, [currentPageVersionsId, setMessages]);
  const handleClearMessages = async () => {
    try {
      const { error } = await supabase
        .from("ai_messages")
        .delete()
        .eq("version", currentPageVersionsId);

      if (error) throw error;

      setMessages([]);
    } catch (error) {
      console.error("Error clearing messages:", error);
    }
  };

  const handleSendMessageWrapper = async () => {
    setIsButtonDisabled(true);
    await handleSendMessage(message, currentPageVersionsId);
    setMessage("");
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };

  return (
    <ChatComponent
      messages={messages}
      setMessages={setMessages}
      handleSendMessage={handleSendMessageWrapper}
      isTyping={isTyping || isGenerating}
      isButtonDisabled={isButtonDisabled}
      setMessage={setMessage}
      message={message}
      currentPageVersionsId={currentPageVersionsId}
      handleClearMessages={handleClearMessages}
  
    />
  );
}

export default ChatInterface;
