import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useToken,
  useToast,
} from "@chakra-ui/react";
import PreviewPage from "Site/PreviewPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadingStatus } from "store/selectors";
import LoadingComponent from "../Site/Utilities/components/LoadingComponent";
import { ReactComponent as ArrowBackIcon } from "../assets/svg/back-arrow.svg";
import { ReactComponent as EyeOutlineIcon } from "../assets/svg/eye-outline.svg";
import { ReactComponent as ArrowForwardIcon } from "../assets/svg/forward-arrow.svg";
import { ReactComponent as SaveChanges } from "../assets/svg/save-changes.svg";
import { setIsUnsavedVersionViewing, triggerRedo, triggerUndo, updateSelectedVersionInhistoryPage } from "../store/editorSlice";
import {
  selectIsUnsavedVersionViewing,
  selectedVersionInhistoryPage,
} from "../store/selectors";
import { useSite } from "./SiteContext"; // Adjust the path as needed
import loadingTexts from "./Utilities/constants/loadingTexts"; // Adjust the path as necessary
import { EDITING_DISABLED_ON_HISTORY } from "./Utilities/constants/messagesConstants";
import moveVersionToTop from "./Utilities/setPageVarsionAsCurrent";
import { supabase} from '../Auth/supabaseClient';
import GjsEditor from "./grapesjs/GrapesEditor";
import { MURKUP_REQUEST } from "./Utilities/constants/murkupRequestConstant";

const LivePreview = () => {
  const {
    livePreviewContent,
    isGenerating,
    isGeneratingImages,
    currentUUID,
    siteId,
    pageId,
    setCurrentVersion,
    setcurrentPageVersionsId,
    versions,
  } = useSite();
  const [currentText, setCurrentText] = useState("");
  const [opacity, setOpacity] = useState(0);
  const [blue500] = useToken("colors", ["blue.300"]); // Fetch the color value
  const isUnsavedVersionViewing = useSelector(selectIsUnsavedVersionViewing);
  const toast = useToast();

  const versionInHistoryPage = useSelector(selectedVersionInhistoryPage);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoadingStatus);
  const handlePreviewClick = () => {
    const baseUrl = window.location.origin;
    const path = `${baseUrl}/preview/${currentUUID}`;
    window.open(path, "_blank");
  };

  useEffect(() => {
    let textChangeInterval;
    if (isGenerating) {
      const updateText = () => {
        setOpacity(0);
        setTimeout(() => {
          setCurrentText(
            loadingTexts[Math.floor(Math.random() * loadingTexts.length)]
          );
          setOpacity(1);
        }, 500);
      };

      updateText();
      textChangeInterval = setInterval(updateText, 2000);
    }

    return () => clearInterval(textChangeInterval);
  }, [isGenerating]);

  const handleViewHistoryVersion = (label, SiteVersionsId) => {
    setCurrentVersion(label);
    setcurrentPageVersionsId(SiteVersionsId);
    dispatch(updateSelectedVersionInhistoryPage(SiteVersionsId));

    toast({
      title: "Version Changed",
      description: `You are now viewing version ${label}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const moveVersionToTop = async (SiteVersionsId, siteId, pageId, versions) => {
    const ERROR_FETCHING_VERSIONS = "Error fetching current versions:";
    const ERROR_VERSION_NOT_FOUND = `Version with id ${SiteVersionsId} not found.`;
    const ERROR_DUPLICATING_VERSION = "Error duplicating version:";
    const UNEXPECTED_ERROR_MOVING_VERSION = "Unexpected error moving version:";

    try {
      const { data: currentVersions, error: fetchError } = await supabase
        .from("page_versions")
        .select(
          `html, ${MURKUP_REQUEST}, id, version, change_description, version`
        )
        .eq("site_id", siteId)
        .eq("page_id", pageId)
        .order("version", { ascending: false });

      if (fetchError) {
        console.error(ERROR_FETCHING_VERSIONS, fetchError);
        return;
      }

      // Find index of the version to move within current versions
      const currentIndex = currentVersions.findIndex(
        (version) => version.id === SiteVersionsId
      );

      if (currentIndex === -1) {
        console.error(ERROR_VERSION_NOT_FOUND);
        return;
      }

      const { html, head, body, css, js, version } =
        currentVersions[currentIndex];
      const latestVersion = Math.max(...currentVersions.map((v) => v.version));
      const newVersion = latestVersion + 1;

      // here i am duplicating the version and add it to the beginning of the list
      const { data, error: duplicateError } = await supabase
        .from("page_versions")
        .insert({
          head,
          body,
          html,
          css,
          js,
          version: newVersion,
          change_description: "Restored version " + version,
          page_id: pageId,
          site_id: siteId,
        })
        .select(`id, version`);

      setcurrentPageVersionsId(data[0].id);
      handleViewHistoryVersion(data[0].version, data[0].id);
      setTimeout(() => {
        dispatch(setIsUnsavedVersionViewing(false));
      }, 100);

      if (duplicateError) {
        console.error(ERROR_DUPLICATING_VERSION, duplicateError);
        return;
      }

      console.log(`Version ${SiteVersionsId} moved to the top successfully.`);
    } catch (error) {
      console.error(UNEXPECTED_ERROR_MOVING_VERSION, error);
    }
  };

  return (
    <Box
      bg="white"
      p={0}
      pr={0}
      height="calc(100vh - 52px)"
      borderRadius="md"
      boxShadow="md"
      position="relative"
    >
      {isGeneratingImages && (
        <Alert
          status="info"
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          width="auto"
          display="flex"
          alignItems="center"
          boxShadow="md"
          borderRadius="md"
        >
          <AlertIcon />
          <Flex align="center">
            <Spinner size="sm" mr={2} />
            <AlertTitle>Generating images, please wait...</AlertTitle>
          </Flex>
        </Alert>
      )}
      {isGenerating ? (
        <Flex direction="column" justify="center" align="center" height="100%">
          <Spinner size="xl" color={blue500} />
          <Text
            mt={4}
            fontSize="lg"
            color="gray.500"
            style={{ opacity, transition: "opacity 0.5s ease-in-out" }}
          >
            {currentText}
          </Text>
        </Flex>
      ) : (
        <>
          <Flex
            justify="space-between"
            align="center"
            className="buttons-menu-wrapper"
          >
            <>
              <LoadingComponent isLoading={isLoading}></LoadingComponent>
              <Text>
                {isUnsavedVersionViewing && EDITING_DISABLED_ON_HISTORY}
              </Text>
            </>

            <Flex
              justify="space-between"
              align="center"
              className="buttons-menu"
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
            >
              {" "}
              {isUnsavedVersionViewing ? (
                <Tooltip label="Restore" placement="top">
                  <Button
                    className="history-btn"
                    variant="text"
                    onClick={() => {
                      moveVersionToTop(versionInHistoryPage, siteId, pageId);
                      toast({
                        title: "Version Restored",
                        description:
                          "The selected version has been restored as a new version.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                    }}
                  >
                    <SaveChanges boxSize={6}></SaveChanges>
                  </Button>
                </Tooltip>
              ) : null}
              <Tooltip label="Undo" placement="top">
                <Button
                  className="history-btn undo-btn"
                  isDisabled={!livePreviewContent || isUnsavedVersionViewing}
                  variant="text"
                  color="black.100"
                  _disabled={{ color: "gray.500" }}
                  onClick={() => dispatch(triggerUndo())}
                >
                  <ArrowBackIcon boxSize={6} />
                </Button>
              </Tooltip>
              <Tooltip label="Redo" placement="top">
                <Button
                  className="history-btn redo-btn"
                  isDisabled={!livePreviewContent || isUnsavedVersionViewing}
                  variant="text"
                  color="black.100"
                  _disabled={{ color: "gray.500" }}
                  onClick={() => dispatch(triggerRedo())}
                >
                  <ArrowForwardIcon boxSize={6} />
                </Button>
              </Tooltip>
              <Tooltip label="Preview" placement="bottom-end">
                <Button
                  className="history-btn preview-btn"
                  isDisabled={!livePreviewContent}
                  variant="text"
                  onClick={handlePreviewClick}
                >
                  <EyeOutlineIcon boxSize={6} />
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
          {livePreviewContent ? (
            <>
              <GjsEditor></GjsEditor>
              {isUnsavedVersionViewing ? (
                <PreviewPage id={currentUUID} pageId={pageId} />
              ) : (
                ""
              )}
            </>
          ) : (
            <Flex
              justify="center"
              align="center"
              className="contentLoaderWrapper"
            >
              <Spinner />
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default LivePreview;
