import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Auth/supabaseClient';
import { ROUTE_PATHS } from 'constants/routeConstants';
import { Spinner, Flex } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';


const ContentGuard = ({ children, ...rest }) => {
  //eslint-disable-next-line
  const { session, loading, profileCompleteness } = useAuth();
  const currentUrl = window.location.href;
  const isGuestPage = currentUrl.includes(ROUTE_PATHS.COMPLETE_PROFILE);
  const isAppPage = currentUrl.includes(ROUTE_PATHS.APP);

if (loading)   return (
  <Box height="100vh" width="100vw" display="flex" justifyContent="center" alignItems="center" bg="gray.100">
    <Box as="img" src="https://siteboon.blob.core.windows.net/images/logo-animated.svg" alt="Loading" width="100px" height="100px" />
  </Box>

);

  if (isGuestPage && profileCompleteness === true) {
    return <Navigate to={ROUTE_PATHS.APP}  />;
  }

  if (!Object.keys(localStorage).some(key => key.includes('auth-token'))) {
    return <Navigate to={ROUTE_PATHS.LOGIN}  />;
  }

  if (!isGuestPage && session && profileCompleteness === false) {
    return <Navigate to={ROUTE_PATHS.COMPLETE_PROFILE}  />;
  }

  if (isAppPage && profileCompleteness !== true) {
    return null;
  }

  return children;
};

export default ContentGuard;
