// theme/styles.js
import { mode } from '@chakra-ui/theme-tools';

export const styles = {
  global: (props) => ({
    body: {
      bg: mode('brand.50', 'brand.900')(props),
      color: mode('brand.900', 'brand.100')(props),
    },
    p: {
      fontSize: '1rem', // Ensures text is legible and accessible
    },
    a: {
      color: mode('#202124', 'brand.200')(props),
      _hover: {
        textDecoration: 'underline',
      },
    },
  }),
};
