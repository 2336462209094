import Tracker from '@openreplay/tracker';


export const tracker = new Tracker({
  projectKey: "my3rGB3ObR78N4dDaVIr",  
  respectDoNotTrack: false,
  __DISABLE_SECURE_MODE: true,

});

