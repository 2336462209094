export function removeWrapperStyles(styleString) {
  const regex = /\[data-gjs-type="wrapper"\]\s*{[^}]*}\s*/g;
  return styleString.replace(regex, "");
}

export function addStyleToWrapper(styleString) {
  const regex = /\[data-gjs-type="wrapper"\]\s*{[^}]*}/;

  if (regex.test(styleString)) {
    return styleString.replace(regex, (match) => {
      if (!/all\s*:\s*inherit\s*;/.test(match)) {
        return match.replace("}", " all: inherit; }");
      }
      return match;
    });
  } else {
    return styleString + '\n[data-gjs-type="wrapper"] { all: inherit; }';
  }
}
