import React from "react";
import { Container, Box, Text, SimpleGrid, Heading } from "@chakra-ui/react";
import { PLANS } from "./constants/plansConstant";

/**
 * PlanStep component
 * @param {{ selectedPlan: string, onSelectPlan: (plan: string) => void, title: string }} props - Component props
 * @returns {JSX.Element}
 */
const PlanStep = ({ selectedPlan, onSelectPlan, title }) => {
  return (
    <Container>
      <Box my="2">
        <Heading padding="20px">{title}</Heading>
      </Box>
      <SimpleGrid columns={3} spacing={11}>
        {PLANS.map((plan) => (
          <Box
            p="3"
            display="flex"
            key={plan.value}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            flexDirection="column"
            justifyContent="center"
            onClick={() => onSelectPlan(plan.value)}
            transform={selectedPlan === plan.value ? 'translateY(-5px) scale(1.005)' : 'translateY(0) scale(1)'}
            transition="all 0.3s ease-in-out"
            boxShadow={
              selectedPlan === plan.value
                ? '0 10px 20px rgba(0, 0, 0, 0.2)'
                : '0 4px 6px rgba(0, 0, 0, 0.1)'
            }
            cursor="pointer"
            height={selectedPlan === plan.value ? "160px" : "140px"}
          >
            <Text as='kbd' fontSize="xl" fontWeight="bold">{plan.price}</Text>
            <Text fontSize="md">{plan.name}</Text>
            <Text as="b">{plan.tasks}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default PlanStep;
