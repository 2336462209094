import { ReactComponent as SavingDoneIcon } from "../../../assets/svg/saving-done.svg";
import { ReactComponent as SavingInProcessIcon } from "../../../assets/svg/saving-in-process.svg";
import { Flex, Text, Box } from "@chakra-ui/react";
import { UPLOADING_CHANGES_MESSAGE } from "../constants/messagesConstants";

/**
 * LoadingComponent - A component that displays a loading or done icon based on the loading state.
 * @param {boolean} isLoading - The loading state to determine which icon to display.
 * @returns {JSX.Element} A Flex container with either a loading or done icon.
 */
export default function LoadingComponent({ isLoading }) {
  return (
    <Flex className="loading-wrapper">
      {isLoading ? (
        <Box className="loading-content">
          <SavingInProcessIcon className="loading-icon" />
          <Text className="loading-message">{UPLOADING_CHANGES_MESSAGE}</Text>
        </Box>
      ) : (
        <SavingDoneIcon className="loading-icon" />
      )}
    </Flex>
  );
}
