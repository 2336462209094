import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Progress,
} from "@chakra-ui/react";
import { useAuth } from "Auth/supabaseClient";
import { ROUTE_PATHS } from "constants/routeConstants";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./OnboardingUserStepper.css";
import PlanStep from "./PlanStep";
import { TEXT } from "./constants/buttonsNamesConstant";
import { STEPS } from "./constants/steps";
import { setOnboardingComplete, updateUserProfile } from './userUtils';

/**
 * OnboardingUserStepper component
 * @returns {JSX.Element}
 */
const OnboardingUserStepper = () => {
  // State type annotation
  /**
   * @type {[number, React.Dispatch<React.SetStateAction<number>>]}
   */
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();
  const { setProfileCompliting } = useAuth();

  /**
    * @typedef {Object} FormData
    * @property {string} first_name
    * @property {string} last_name
    * @property {string} gender
    * @property {string} company_name
    * @property {string} plan
    */

  /**
    * @type {[FormData, React.Dispatch<React.SetStateAction<FormData>>]}
    */  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    plan: "",
  });

  /**
   * Handle input change event
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event
   * @returns {void}
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  /**
   * Handle the selection of a pricing plan
   * @param {string} plan - The selected pricing plan
   * @returns {void}
   */
  const handlePlanSelect = (plan) => {
    setFormData((prevData) => ({
      ...prevData,
      plan: plan,
    }));
  };

  /**
   * Toggle between steps
   * @param {"forward" | "back"} way - Direction to toggle the step
   * @returns {void}
   */
  const toggleStep = (way) => {
    const isForward = way === "forward";

    if (isForward && !isStepValid()) return;
  
    if (stepIndex < STEPS.length - 1 && isForward) {
      setStepIndex(stepIndex + 1);
    }
    else if (stepIndex < STEPS.length && !isForward) {
      setStepIndex(stepIndex - 1);
    } 
    else {
      handleDone();
    }
  };

  /**
   * Check if the current step is valid
   * @returns {boolean} - True if valid, false otherwise
   */
  const isStepValid = () => {
    const step = STEPS[stepIndex];
    if (step.required) {
      return !!formData[step.name];
    }
    return true;
  };

  /**
   * Handle the completion of the stepper process.
   * @param {void}
   * @returns {Promise<void>}
   */
  const handleDone = async () => {

    const userData = {
      first_name: formData.first_name,
      last_name: formData.last_name
    }

    const accountData = {
      company_name: formData.company_name,
      plan: formData.plan
    }

    try {
      const { account_id, error } = await updateUserProfile(userData);
      if (error) {
        console.error('Failed to create user profile:', error);
      } else {
        await setOnboardingComplete(account_id);
        setProfileCompliting(!!accountData);
        navigate(ROUTE_PATHS.APP);
      }
    } catch (error) {
      console.error('Error in handleDone:', error);
    }
  };

  return (
    <Box display="flex" alignItems="center" height="100vh">
      <Container textAlign="center">
        <Card my="6">
          <CardHeader>
            <Box my="8">
              <Progress
                value={((stepIndex + 1) / STEPS.length) * 100}
                height="6px"
              />
            </Box>
          </CardHeader>
          <CardBody>
            <Box my="6">
              {STEPS.map(
                (step, index) =>
                  index === stepIndex && (
                    <Box
                      key={index}
                      className="fadeInRight"
                    >
                      {index < STEPS.length - 1 ? (
                        <FormControl>
                          <FormLabel
                            textAlign="center"
                            paddingBottom="40px"
                            fontSize="lg"
                            mb="2"
                          >
                            <Heading padding="20px">{step.title}</Heading>
                          </FormLabel>
                          <Input
                            size="lg"
                            name={step.name}
                            placeholder={step.placeholder}
                            value={formData[step.name]}
                            onChange={handleChange}
                            width="95%"
                            mb="6"
                          />
                        </FormControl>
                      ) : (
                        <PlanStep
                          selectedPlan={formData.plan}
                          onSelectPlan={handlePlanSelect}
                          title={step.title}
                        />
                      )}
                    </Box>
                  )
              )}
            </Box>
            <CardFooter
              paddingBottom="2px"
              display="flex"
              flexDirection="column"
            >
              <Box display="flex" width="100%" mt="auto">
                <Box display="flex" justifyContent="left" width="50%">
                  {stepIndex !== 0 && (
                    <Button
                      colorScheme="blue"
                      size="md"
                      px="4"
                      onClick={() => toggleStep("back")}
                    >
                      {TEXT.BACK}
                    </Button>
                  )}
                </Box>
                <Box display="flex" justifyContent="right" width="50%">
                  <Button
                    colorScheme="blue"
                    size="md"
                    px="4"
                    onClick={() => toggleStep("forward")}
                    isDisabled={!isStepValid()}
                  >
                    {stepIndex >= STEPS.length - 1 ? TEXT.DONE : TEXT.CONTINUE}
                  </Button>
                </Box>
              </Box>
            </CardFooter>
          </CardBody>
        </Card>
      </Container>
    </Box>
  );
};

export default OnboardingUserStepper;
