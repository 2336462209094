/* eslint-disable */
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/supabaseClient';
import { ROUTE_PATHS } from 'constants/routeConstants';

/**
 * LoginGuard component
 * 
 * Props:
 * @param {React.ReactNode} children - The child components to render if conditions are met.
 * @param {object} rest - Any additional props passed to the component.
 * 
 * Returns:
 * @returns {React.ReactNode} - The rendered children if conditions are met, otherwise navigates to a different route.
 */
const LoginGuard = ({ children, ...rest }) => {
  const navigate = useNavigate();
  const { profileCompleteness } = useAuth();

  if (profileCompleteness || Object.keys(localStorage).some(key => key.includes('auth-token'))) {
    navigate(ROUTE_PATHS.APP);
    return null;
  }
  
  return children;
};

export default LoginGuard;