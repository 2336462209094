import { CopyIcon } from "@chakra-ui/icons";
import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { useSite } from "Site/SiteContext";
import { FloatingMenu } from "Site/Utilities/components/FloatingMenu";
import { Tooltip } from "Site/Utilities/components/cuttom-tooltip/CustomTooltip";
import { ID } from "Site/Utilities/constants/messagesConstants";
import { extractScriptTagsFromHtml } from "Site/Utilities/parseMarkup/extractScriptTags";
import extractHeadTags from "Site/Utilities/parseMarkup/extractTagsToHead";
import { FaMagic } from "react-icons/fa"; // Import the magic wand icon
import {
  addStyleToWrapper,
  removeWrapperStyles,
} from "Site/Utilities/parseMarkup/handleGjsWrapperStyles";
import mergeHeadAndBody from "Site/Utilities/parseMarkup/mergeHeadAndBody";
import cssStringToObject from "Site/Utilities/parseMarkup/parseCssToJs";
import { pullStylesAndModifyHTML } from "Site/Utilities/parseMarkup/pullStylesAndModifyHTML";
import { ReactComponent as EditIcon } from "../../assets/svg/text-edit.svg";
import { ReactComponent as EditImage } from "../../assets/svg/paint.svg";
import grapesjs from "grapesjs";
import "./custom-grapesjs.css"; //  Here we import the custom css for the panels
import { FaEye, FaCode, FaCog } from "react-icons/fa";

import "grapesjs/dist/css/grapes.min.css";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCommand,
  setBody,
  setCss,
  setHead,
  setLoading,
} from "store/editorSlice";
import {
  selectBody,
  selectCss,
  selectHead,
  selectIsUnsavedVersionViewing,
  selectJs,
  selectReRenderTemplate,
} from "store/selectors";
import { editorConfig } from "./gjsConfig";

const MemoizedFloatingMenu = React.memo(FloatingMenu);

const GrapesEditor = () => {
  const editorRef = useRef(null);
  const editorInstance = useRef(null);
  const command = useSelector((state) => state.editor.command);
  const fetchedJs = useSelector(selectJs);
  const fetchedCss = useSelector(selectCss);
  const fetchedBody = useSelector(selectBody);
  const fetchedHead = useSelector(selectHead);
  const isUnsavedVersionViewing = useSelector(selectIsUnsavedVersionViewing);
  
  const triggerReRender = useSelector(selectReRenderTemplate);
  const [isComponentFreshLoaded, setNotFresh] = useState(true);
  const {
    updateMarkupPartially,
    setcurrentPageVersionsId,
    currentPageVersionsId,
    versions,
  } = useSite();
  const isEditorInUpdating = useRef(false);
  const isSetingNewVersion = useRef(false);
  const selectedComponentId = useRef(null);
  const dispatch = useDispatch();
  const mousePosition = useRef({ x: 0, y: 0 });

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(setUpGrapesEditor, [triggerReRender]);

  useEffect(updateEditorContent, [
    fetchedBody,
    fetchedJs,
    fetchedHead,
    fetchedCss,
    isUnsavedVersionViewing,
  ]);
  /**
   * Initializes the GrapesJS editor with necessary configurations.
   */
  function setUpGrapesEditor() {
    const editor = grapesjs.init({
      ...editorConfig,
      container: editorRef.current,
      commands: {
        defaults: [
          {
            id: "tlb-request-change",
            run() {
              onOpen();
            },
          },
          {
            id: "open-text-editor",
            run(editor) {
              onClose(); // closing the ai request menu
              editor.getSelected().trigger("active");
            },
          },
          {
            id: "copy-id",
            run(editor) {
              navigator.clipboard.writeText(selectedComponentId.current);
              document.querySelector(".copy-id .chakra-text").style.background =
                "green";
              setTimeout(() => {
                document.querySelector(
                  ".copy-id .chakra-text"
                ).style.background = "#3C8DFF";
              }, 1000);
            },
          },
        ],
      },
      storageManager: {
        id: "gjs-",
        type: "local",
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
      },
      canvasCss: `
        .gjs-selected {
        outline: 2px solid #3C8DFF !important;
        border-radius: 3px;
      }
    `,
    });

    editorInstance.current = editor;

    editor.on("load", () => {
      isSetingNewVersion.current = false;
    });

    /**
     * Handles the update event of the editor.
     */
    editor.on("update", () => {
      localStorage.removeItem("gjsProject");

      if (isSetingNewVersion.current) {
        isSetingNewVersion.current = false;
        return;
      }

      isEditorInUpdating.current = true;

      const formattedStyles = cssStringToObject(
        editorInstance.current.getCss({ avoidProtected: true })
      );
      const clearedStyles = removeWrapperStyles(formattedStyles);
      const { headTags, cleanedHtml } = extractHeadTags(
        editorInstance.current.getHtml()
      );
      const { htmlWithoutScripts } = extractScriptTagsFromHtml(cleanedHtml);

      dispatch(setHead(headTags));
      dispatch(setBody(htmlWithoutScripts));
      dispatch(setCss(clearedStyles));

      dispatch(setLoading(true));

      updateMarkupPartially("head", headTags);
      updateMarkupPartially("body", htmlWithoutScripts);
      updateMarkupPartially("css", clearedStyles);
    });

    editor.on("component:selected", () => {
      const selectedComponent = editor.getSelected();
      selectedComponentId.current = selectedComponent.ccid;
      const contentType = selectedComponent.attributes.type;
      let icon = false;
      if (contentType === "text") {
        icon = <EditIcon />;
      } else if (contentType === "image") {
        icon = <EditImage />;
      }

      const defaultToolbar = selectedComponent.get("toolbar").filter((item) => {
        const excludedCommands = [
          "move",
          "clone",
          "open-text-editor",
          "tlb-request-change",
          "copy-id",
        ];
        return (
          typeof item?.command !== "string" ||
          !excludedCommands.some((cmd) => item.command.includes(cmd))
        );
      });

      const combinedToolbar = [
        {
          label: renderToStaticMarkup(
            <Box className="copy-id">
              <Text fontSize="xs">{ID + selectedComponentId.current}</Text>
              <CopyIcon fontSize="xs" />
            </Box>
          ),
          title: ID + selectedComponentId.current,
          command: "copy-id",
        },
        {
          label: renderToStaticMarkup(
            <div className="gjs-toolbar-divider"></div>
          ),
          command: "",
        },
        ...defaultToolbar,
        {
          label: renderToStaticMarkup(<FaMagic />),
          title: "request change",
          command: "tlb-request-change",
        },
        ...(icon
          ? [
              {
                label: renderToStaticMarkup(icon),
                title: "edit",
                command: "open-text-editor",
              },
            ]
          : []),
      ];

      
      
      const updatedToolbar = combinedToolbar.map((item, index) => {

        if (item.label && item.command !== "") {
          let tooltipLabel = "";
          if ((index === 2) ) {
            tooltipLabel = 'Select Parent';
          }  else if (item.command === "tlb-delete") {
            tooltipLabel = "Delete";
          } else if (item.title) {
            tooltipLabel =
              item.title.charAt(0).toUpperCase() + item.title.slice(1);
          }

          const tooltipElement = (
            <Tooltip label={tooltipLabel} placement="top">
              <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
            </Tooltip>
          );
          item.label = renderToStaticMarkup(tooltipElement);
        }
        return item;
      });

      selectedComponent.set({
        toolbar: updatedToolbar,
      });
    });

    return () => {
      editor.destroy();
    };
  }

  /**
   * Updates the editor's content when fetched data changes.
   */
  function updateEditorContent() {
    if (!editorInstance.current || !fetchedBody || !fetchedCss || !fetchedHead)
      return;
    if (isUnsavedVersionViewing) return;

    if (isEditorInUpdating.current) {
      isEditorInUpdating.current = false;
      return;
    }

    isSetingNewVersion.current = true;

    const grapesjsBodyWithAllTags = mergeHeadAndBody(fetchedHead, fetchedBody);

    const [styleTagStyles, styleAttrStyles, modifiedMarkup] =
      pullStylesAndModifyHTML(grapesjsBodyWithAllTags);

    if (styleTagStyles)
      editorInstance.current.setStyle(cssStringToObject(styleTagStyles));

    if (styleAttrStyles) applyAttrCssRulesToGrapesJS(styleAttrStyles);

    const cssToWiewInGrapesjs = addStyleToWrapper(fetchedCss);

    editorInstance.current.setStyle({});
    editorInstance.current.setComponents(modifiedMarkup);
    editorInstance.current.setStyle(cssStringToObject(cssToWiewInGrapesjs));

    if (isComponentFreshLoaded && fetchedJs) {
      setNotFresh(false);
      addScriptsToEditor(fetchedJs);
      editorInstance.current.UndoManager.clear();
    }
  }

  useEffect(() => {
    if (command && editorInstance.current) {
      handleCommand(command);
      dispatch(clearCommand());
    }
  }, [command, dispatch]);

  /**
   * Applies CSS rules to GrapesJS editor based on the provided styles array.
   * @param {Object[]} stylesArray - Array of objects containing elementSelector and cssStyle.
   * @param {Object} editor - Instance of GrapesJS editor.
   */
  function applyAttrCssRulesToGrapesJS(stylesArray) {
    const cssRules = stylesArray
      .map((styleObj) => {
        return `${styleObj.elementSelector} { ${styleObj.cssStyle} }`;
      })
      .join("\n");

    editorInstance.current.CssComposer.add(cssRules);
  }

  /**
   * Handles editor commands like undo and redo.
   * @param {string} command - The command to execute.
   */
  function handleCommand(command) {
    if (command === "undo") {
      editorInstance.current.UndoManager.undo();
    } else if (command === "redo") {
      editorInstance.current.UndoManager.redo();
    }
  }

  /**
   * Adds a script to the editor.
   * @param {string} content - The script content to add.
   */
  function addScriptToEditor(content) {
    editorInstance.current.addComponents({
      tagName: "script",
      type: "script",
      content: content,
    });
  }

  /**
   * Adds multiple scripts to the editor.
   * @param {Array|string} scripts - The scripts to add.
   */
  function addScriptsToEditor(scripts) {
    if (Array.isArray(scripts)) {
      scripts.forEach((content) => addScriptToEditor(content));
    } else {
      addScriptToEditor(scripts);
    }
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      mousePosition.current = {
        x: event.clientX,
        y: event.clientY,
      };
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    if (isUnsavedVersionViewing) return;

    const buttonsMenuWrapper = document.querySelector(".buttons-menu-wrapper");

    if (buttonsMenuWrapper) {
      const sibling = buttonsMenuWrapper.nextElementSibling;

      if (sibling) {
        sibling.style.height = "100%";
      }
    }
  }, [isUnsavedVersionViewing]);

  useEffect(() => {
    setcurrentPageVersionsId(versions[0].SiteVersionsId);
  },[versions])

  return (
    <>
      <Box
        visibility={isUnsavedVersionViewing ? "hidden" : "visible"}
        position={isUnsavedVersionViewing ? "fixed" : "relative"}
        zIndex={isUnsavedVersionViewing ? "-100000" : "0"}
        ref={editorRef}
      >
        {isOpen && (
          <MemoizedFloatingMenu
            selectedComponentId={selectedComponentId.current}
            onClose={onClose}
            mousePosition={mousePosition.current}
          ></MemoizedFloatingMenu>
        )}
      </Box>
    </>
  );
};

export default GrapesEditor;
