import React, { useState, useEffect, useCallback } from "react";
import {
  VStack,
  Text,
  Input,
  Button,
  useToast,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  HStack,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import {
  supabase,
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "../Auth/supabaseClient";
import { useSite } from "./SiteContext";
import {
  CheckIcon,
  WarningIcon,
  RepeatIcon,
  CopyIcon,
  EditIcon,
  CloseIcon,
} from "@chakra-ui/icons";

function PublishComponent() {
  const { siteId } = useSite();
  const [domain, setDomain] = useState("");
  const [isPublishing, setIsPublishing] = useState(false);
  const [existingDomain, setExistingDomain] = useState("");
  const [isCnameValid, setIsCnameValid] = useState(false);
  const [isCheckingDns, setIsCheckingDns] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();


  const getDomainPrefix = (domain) => {
    const parts = domain.split('.');
    return parts.length > 2 ? parts[0] : 'root';
  };
  
  const cnameValue = `${getDomainPrefix(domain)} CNAME site-${siteId}.netlify.app`;

  const { hasCopied, onCopy } = useClipboard(cnameValue);



  const fetchCnameRecord = useCallback(
    async (domain) => {
      setIsCheckingDns(true);
      try {
        const response = await fetch(
          `https://cloudflare-dns.com/dns-query?name=${domain}&type=CNAME`,
          {
            headers: {
              accept: "application/dns-json",
            },
          }
        );
        const data = await response.json();

        if (data.Answer && data.Answer.length > 0) {
          const cname = data.Answer[0].data;
          setIsCnameValid(cname.includes(`site-${siteId}.netlify.app`));
        } else {
          setIsCnameValid(false);
        }
      } catch (error) {
        console.error("Error fetching CNAME record", error);
        setIsCnameValid(false);
      } finally {
        setIsCheckingDns(false);
      }
    },
    [siteId]
  );

  useEffect(() => {
    const fetchSiteData = async () => {
      const { data, error } = await supabase
        .from("site")
        .select("domain_name")
        .eq("id", siteId)
        .single();

      if (error) {
        console.error("Error fetching site data", error);
      } else {
        setExistingDomain(data.domain_name || "");
        setDomain(data.domain_name || ""); // Prefill the domain input
        if (data.domain_name) {
          fetchCnameRecord(data.domain_name); // Fetch CNAME record if domain exists
        }
      }
    };

    const siteSubscription = supabase
      .channel("realtime:public:site")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "site",
          filter: `id=eq.${siteId}`,
        },
        (payload) => {
          setExistingDomain(payload.new.domain_name || "");
          setDomain(payload.new.domain_name || ""); // Update the domain input if it changes in the DB
          if (payload.new.domain_name) {
            fetchCnameRecord(payload.new.domain_name); // Fetch CNAME record if domain exists
          }
        }
      )
      .subscribe();

    fetchSiteData();

    return () => {
      supabase.removeChannel(siteSubscription);
    };
  }, [siteId, fetchCnameRecord]);

  const handlePublish = async () => {
    setIsPublishing(true);
    const previousDomain = existingDomain; // Store the previous domain value
    try {
      // Publish the new domain
      const { error: publishError } = await supabase.functions.invoke(
        "publish-website",
        {
          body: JSON.stringify({
            action: "publish",
            domain,
            siteId: siteId,
          }),
        }
      );

      if (publishError) {
        throw publishError;
      }

      toast({
        title: "Website Published",
        description: `Your website has been published at ${domain}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        icon: <CheckIcon />,
      });

      // Re-fetch the CNAME record after publishing
      fetchCnameRecord(domain);
    } catch (error) {
      console.error("Error publishing website", error);

      // Reset the domain to the previous value if an error occurs
      setDomain(previousDomain);

      if (error instanceof FunctionsHttpError) {
        const errorMessage = await error.context.json();
        if (error.context.status === 409) {
          toast({
            title: "Error",
            description:
              "This domain is already in use. Please choose another one.",
            status: "error",
            duration: 5000,
            isClosable: true,
            icon: <WarningIcon />,
          });
        } else {
          toast({
            title: "Error",
            description: errorMessage.error || "An unexpected error occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
            icon: <WarningIcon />,
          });
        }
      } else if (error instanceof FunctionsRelayError) {
        toast({
          title: "Relay Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          icon: <WarningIcon />,
        });
      } else if (error instanceof FunctionsFetchError) {
        toast({
          title: "Fetch Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          icon: <WarningIcon />,
        });
      } else {
        toast({
          title: "Error",
          description:
            "There was an error publishing your website. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          icon: <WarningIcon />,
        });
      }
    } finally {
      setIsPublishing(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setDomain(existingDomain);
  };

  const handleSave = async () => {
    setIsEditing(false);
    try {
      fetchCnameRecord(domain);
      handlePublish(); // Call handlePublish to perform the necessary updates
    } catch (error) {
      console.error("Error updating domain", error);
      toast({
        title: "Error",
        description:
          "There was an error updating your domain. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        icon: <WarningIcon />,
      });
    }
  };

  const isValidDomain = (domain) => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
    return domainPattern.test(domain);
  };

  const isDomainValid = isValidDomain(domain);

  return (
    <Box maxW="md">
      <Text color="gray.300" mb={4} fontSize="lg" fontWeight="bold">
        Publish Your Website
      </Text>
      <VStack spacing={4} flex="1" overflowY="auto">
        <FormControl isInvalid={!isDomainValid && domain.length > 0}>
          <FormLabel color="white">Domain/Subdomain:</FormLabel>
          {isEditing || !existingDomain ? (
            <HStack>
              <Input
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder="e.g., mywebsite.com or sub.mywebsite.com"
                color="white"
                bg="gray.700"
                w="full"
                borderColor={
                  isDomainValid || domain === "" ? "gray.600" : "red.500"
                }
              />
              {isEditing && (
                <Button
                  size="sm"
                  onClick={handleCancel}
                  colorScheme="red"
                  variant="ghost"
                >
                  <CloseIcon />
                </Button>
              )}
            </HStack>
          ) : (
            <HStack>
              <Text
                color="white"
                bg="gray.700"
                p={2}
                borderRadius="md"
                w="full"
              >
                {domain}
              </Text>
              <Button
                size="sm"
                onClick={handleEdit}
                colorScheme="blue"
                variant="ghost"
              >
                <EditIcon />
              </Button>
            </HStack>
          )}
          {!isDomainValid && domain.length > 0 && (
            <FormErrorMessage>Invalid domain name.</FormErrorMessage>
          )}
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={isEditing ? handleSave : handlePublish}
          isLoading={isPublishing}
          isDisabled={!isDomainValid || domain === ""}
          spinner={<Spinner size="sm" />}
        >
          {isPublishing
            ? "Publishing..."
            : existingDomain
              ? isEditing
                ? "Update Website & Domain"
                : "Update Website"
              : "Publish"}
        </Button>
        {existingDomain && (
          <HStack>
            {isCheckingDns ? (
              <>
                <Spinner size="sm" />
                <Text color="yellow.500">Checking the DNS of your website</Text>
              </>
            ) : isCnameValid ? (
              <>
                <CheckIcon color="green.500" />
                <Text color="green.500">Your site is live and accessible</Text>
              </>
            ) : (
              <>
                <WarningIcon color="red.500" />
                <Text color="red.500">Please configure your DNS</Text>
              </>
            )}
            <Button
              size="sm"
              onClick={() => fetchCnameRecord(domain)}
              colorScheme="blue"
              variant="ghost"
              aria-label="Check again"

            >
              <RepeatIcon />
            </Button>
          </HStack>
        )}
        {existingDomain && (
          <Link color="blue.500" onClick={onOpen}>
            How to configure your DNS
          </Link>
        )}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>DNS Configuration Instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isCnameValid && (
              <Text mb={4} fontSize="sm" fontStyle="italic">
                This domain is waiting for External DNS propagation (that can
                take up to 24 hours) or has not been configured properly.
                Confirm you have configured this domain properly with the
                suggestions below or check out the documentation.
              </Text>
            )}
            <Text mb={2}>
            Point <strong>{domain.split('.').length > 2 ? domain.split('.')[0] : ''}</strong> {domain.split('.').length <= 2 ? 'root' : ''} CNAME record to <strong>site-{siteId}.netlify.app</strong>
            </Text>
            <Text mb={2}>
              Log in to the account you have with your DNS provider, and add a
              CNAME record for <strong>{domain.split('.').length > 2 ? domain.split('.')[0] : ''}</strong> pointing to{" "}
              <strong>site-{siteId}.netlify.app</strong>.
            </Text>
            <Box
              bg="gray.100"
              p={2}
              borderRadius="md"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text>
                <strong>{domain.split('.').length > 2 ? domain.split('.')[0] : ''}</strong> CNAME{" "}
                <strong>site-{siteId}.netlify.app</strong>
              </Text>
              <Button size="sm" onClick={onCopy} ml={2}>
                {hasCopied ? (
                  <>
                    Copied <CheckIcon ml={1} />
                  </>
                ) : (
                  <CopyIcon />
                )}
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default PublishComponent;
