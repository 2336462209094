/**
 * @typedef {Object} RootState
 * @property {Object} editor
 * @property {boolean} editor.isLoading
 * @property {string|null} editor.command
 * @property {string} editor.css
 * @property {string} editor.js
 * @property {string} editor.body
 * @property {string} editor.head
 * @property {boolean} editor.templateRenderTrigger
 */

/**
 * @param {RootState} state
 * @returns {boolean}
 */
export const selectLoadingStatus = (state) => state.editor.isLoading;

/**
 * @param {RootState} state
 * @returns {string|null}
 */
export const selectCommand = (state) => state.editor.command;

/**
 * @param {RootState} state
 * @returns {string}
 */
export const selectJs = (state) => state.editor.js;

/**
 * @param {RootState} state
 * @returns {string}
 */
export const selectCss = (state) => state.editor.css;

/**
 * @param {RootState} state
 * @returns {string}
 */
export const selectBody = (state) => state.editor.body;

/**
 * @param {RootState} state
 * @returns {string}
 */
export const selectHead = (state) => state.editor.head;

/**
 * @param {RootState} state
 * @returns {string}
 */
export const selectIsUnsavedVersionViewing = (state) => state.editor.isUnsavedVersionViewing;

/**
 * @param {RootState} state
 * @returns {string}
 */
export const selectedVersionInhistoryPage = (state) => state.editor.selectedVersionInhistoryPage;

/**
 * @param {RootState} state
 * @returns {boolean}
 */
export const selectReRenderTemplate = (state) => state.editor.templateRenderTrigger;