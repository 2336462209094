//supabaseClient.js
import {
  createClient,
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";
import { ROUTE_PATHS } from "constants/routeConstants";
import { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

const supabaseUrl = "https://rbfdagomafltuzfmfmgk.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJiZmRhZ29tYWZsdHV6Zm1mbWdrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc3MzYxNzcsImV4cCI6MjAyMzMxMjE3N30.AvBb6bnxf3Oq3tBZDtOvjsGwqXxYmMJiL4Nnt4BkuAk";
export const supabase = createClient(supabaseUrl, supabaseKey, {
  db: {
    schema: "public", // this can be overridden by passing `meta.schema` to data hooks.
  },
  auth: {
    persistSession: true,
  },
});

export { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError };

// Auth Context to provide authentication state across the application
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);  
  const [profileCompleteness, setProfileCompliting] = useState(null);
  const navigate = useNavigate();

  // Function to handle logout
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      // Handle logout error if necessary
      console.error("Logout error:", error);
    }
    // Clear the session state
    setSession(null);
  };

  useEffect(() => {
    // Check for an existing session and update state
    const getSession = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();
        if (error) {
          console.error("Error getting session:", error);
        } else {
          const account = await supabase
            .from("account")
            .select("onboarding_complete")
            .single();

          setProfileCompliting(() => account.data?.onboarding_complete);

          setSession(data.session);
        }
      } catch (err) {
        console.error("Unexpected error fetching initial session:", err);
      } finally {
        setLoading(false);
      }
    };

    getSession();
    // Subscribe to auth state changes
    const { data: subscription } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
          setSession(session);
        } else if (event === "SIGNED_OUT") {
          setSession(null);
        }
      }
    );

    // Unsubscribe on cleanup
    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
    };
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ session, loading, logout, profileCompleteness, setProfileCompliting }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use the auth context
export const useAuth = () => useContext(AuthContext);
