import { supabase } from "Auth/supabaseClient";

/**
 * UserProfile type definition
 * @typedef {Object} UserProfile
 * @property {number} id - The ID of the user profile
 * @property {string} created_at - The creation timestamp of the user profile
 * @property {number} account - The account ID associated with the user profile
 * @property {string} user - The user ID (UUID) associated with the user profile
 * @property {string|null} first_name - The first name of the user
 * @property {string|null} last_name - The last name of the user
 * @property {string} role - The role of the user
 */

/**
 * Create a user profile
 * @param {Partial<UserProfile>} profile - The user profile to create
 * @returns {Promise<{account_id: string, error: any}>}
 */
export async function updateUserProfile(profile) {
  const { data: { user } } = await supabase.auth.getUser();
  
  const { data, error } = await supabase
    .from('user_profile')
    .update(profile)
    .eq('user', user.id)
    .select('*')

  if (error) {
    console.error('Error creating user profile:', error.message);
    return { error };
  }

  return { account_id: data[0].account};
}

/**
 * Set OnBoarding complete
 * @returns {Promise<{data: any, error: any}>}
 */
export async function setOnboardingComplete(accountId, data) {
  // if (!accountId) return;

  const { data: updatedData, error: updateError } = await supabase
    .from('account')
    .update({ ...data, onboarding_complete: true })
    .eq('id', accountId)

  if (updateError) {
    console.error('Error updating account onboarding:', updateError.message);
    return { error: updateError };
  }

  return { data: updatedData };
}

/**
 * Delete a user profile
 * @param {string} userId - The ID of the user to delete
 * @returns {Promise<{data: any, error: any}>}
 */
export async function deleteUserProfile(userId) {
  const { data, error } = await supabase
    .from('user_profile')
    .delete()
    .eq('user', userId);

  if (error) {
    console.error('Error deleting user profile:', error.message);
    return { error };
  }
  return { data };
}

/**
 * Check if user profile is complete
 * @param {string} userId - The ID of the user to check
 * @returns {Promise<{isComplete: boolean, data: any, error: any}>}
 */
export async function isUserProfileComplete() {
  const { data: { user } } = await supabase.auth.getUser();
  const { data, error } = await supabase
    .from('account')
    .select('onboarding_complete')
    .eq('user', user.id)

  if (error) {
    console.error('Error fetching user profile:', error.message);
    return { error };
  }

  const isComplete = data.first_name !== null && data.last_name !== null;
  return { isComplete, data };
}