import React, { useState, useEffect, useMemo } from "react";
import { useClipboard } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
 



import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import { supabase } from "./Auth/supabaseClient"; // Adjust the import path as needed
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  VStack,
  HStack,
} from "@chakra-ui/react";

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <Input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search all columns..."
      mb={4}
      color="white"
      _placeholder={{ color: "gray.300" }}
    />
  );
}

function Test() {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [displayCount, setDisplayCount] = useState(5);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);




  const [isCopied, setIsCopied] = useState(false);
  const { onCopy } = useClipboard(modalContent);
  const handleSave = async () => {
    const { data, error } = await supabase
      .from("page_versions")
      .update({ [modalHeader.toLowerCase()]: modalContent })
      .eq('id', currentRowId);
  
    if (error) {
      console.error("Error updating content:", error);
    } else {
      setIsEditing(false);
      // Update local data
      setData(prevData => prevData.map(row => 
        row.id === currentRowId 
          ? { ...row, [modalHeader.toLowerCase()]: modalContent }
          : row
      ));
    }
  };
  
  
  
  



  const handleCopy = () => {
    onCopy();
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset isCopied after 2 seconds
  };

  useEffect(() => {
    fetchPageVersions();
  }, []);

  const fetchPageVersions = async () => {
    //change the below supabase to display only the rows where last_modified is not null
    
    const { data, error } = await supabase
      .from("page_versions")
      .select("id,site_id, version, head, body, css, js, html,last_modified")
      .not('last_modified', 'is', null)

      .limit(5)
      .order("last_modified", { ascending: false });

    if (error) console.error("Error fetching page versions:", error);
    else setData(data);
  };

  const columns = useMemo(
    () => [
      { Header: "Site ID", accessor: "site_id" },
      { Header: "Version", accessor: "version" },
      {
        Header: "Head",
        accessor: "head",
        Cell: ({ value, row }) => (
          <TruncatedCell
            value={value}
            fieldName="Head"
            rowId={row.original.id}
          />
        ),
        width: 300,
      },
      {
        Header: "Body",
        accessor: "body",
        Cell: ({ value, row }) => (
          <TruncatedCell
            value={value}
            fieldName="Body"
            rowId={row.original.id}
          />
        ),
        width: 300,
      },
      {
        Header: "CSS",
        accessor: "css",
        Cell: ({ value, row }) => (
          <TruncatedCell
            value={value}
            fieldName="css"
            rowId={row.original.id}
          />
        ),
        width: 300,
      },
      {
        Header: "JS",
        accessor: "js",
        Cell: ({ value, row }) => (
          <TruncatedCell value={value} fieldName="JS" rowId={row.original.id} />
        ),
        width: 300,
      },
      {
        Header: "HTML",
        accessor: "html",
        Cell: ({ value, row }) => (
          <TruncatedCell
            value={value}
            fieldName="HTML"
            rowId={row.original.id}
          />
        ),
        width: 300,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy);

  const TruncatedCell = ({ value, fieldName, rowId }) => {
    const trimmedValue = value ? value.trim() : "";
    const truncatedValue =
      trimmedValue.substring(0, 100) + (trimmedValue.length > 100 ? "..." : "");

    const handleShowMore = () => {
      setModalHeader(fieldName);
      setModalContent(trimmedValue);
      setCurrentRowId(rowId);
      setIsOpen(true);
    };

    return (
      <Box>
        <Text as="pre" whiteSpace="pre-wrap" color="white" fontSize="12px">
          {truncatedValue}
        </Text>
        {trimmedValue.length > 100 && (
          <Button size="sm" onClick={handleShowMore} mt={2}>
            Show More
          </Button>
        )}
      </Box>
    );
  };

  const loadMoreRows = async () => {
    const { data: newData, error } = await supabase
      .from("page_versions")
      .select("id, site_id, version, head, body, css, js, html")
      .range(displayCount, displayCount + 2)
      .order("last_modified", { ascending: false });

    if (error) console.error("Error fetching more page versions:", error);
    else {
      setData([...data, ...newData]);
      setDisplayCount(displayCount + 5);
    }
  };
  useEffect(() => {
    const channel = supabase
      .channel("realtime:public:page_versions")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "page_versions" },
        (payload) => {
          // Update the data in state
          setData((currentData) =>
            currentData.map((row) =>
              row.id === payload.new.id ? { ...row, ...payload.new } : row
            )
          );

          // If the modal is open and the updated row is being displayed, update the modal content
          if (isOpen && modalHeader === payload.new.id) {
            setModalContent(payload.new[modalHeader.toLowerCase()]);
          }
        }
      )    .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "page_versions" },
        (payload) => {
          // Handle the inserted data
          setData((currentData) => [...currentData, payload.new]);
        }
      )
      .subscribe();
  

    return () => {
      supabase.removeChannel(channel);
    };
  }, [isOpen, modalHeader]);

  return (
    <Box
      bg="black"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      pt="30px" // This adds 30px padding to the top
    >
      <VStack spacing={4} align="stretch" width="90%">
        <GlobalFilter
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Box overflowY="auto" height="calc(100% - 40px)">
          <Table
            {...getTableProps()}
            variant="simple"
            borderWidth="1px"
            borderColor="gray.600"
            color="white"
            layout="fixed"
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      borderBottomWidth="2px"
                      p={2}
                      color="gray.300"
                      width={column.width}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.slice(0, displayCount).map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} _hover={{ bg: "gray.900" }}>
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps()}
                        borderBottomWidth="1px"
                        p={2}
                        width={cell.column.width}
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <HStack justifyContent="center">
          <Button onClick={loadMoreRows} colorScheme="blue">
            Show More
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent
          bg="gray.800"
          color="white"
          width="80%"
          maxWidth="80%"
          margin="auto"
        >
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
  {isEditing ? (
    <textarea
      value={modalContent}
      onChange={(e) => setModalContent(e.target.value)}
      style={{ width: '100%', height: '300px', color: 'black' }}
    />
  ) : (
    <Text as="pre" whiteSpace="pre-wrap" fontSize="12px">
      {data.find((row) => row.id === currentRowId)?.[modalHeader.toLowerCase()] || modalContent}
    </Text>
  )}
  <Button
    mt={4}
    onClick={handleCopy}
    colorScheme={isCopied ? "green" : "blue"}
    mr={2}
  >
    {isCopied ? (
      <>
        <CheckIcon mr={2} />
        Copied
      </>
    ) : (
      "Copy"
    )}
  </Button>
  <Button
    mt={4}
    onClick={() => setIsEditing(!isEditing)}
    colorScheme="yellow"
  >
    {isEditing ? "Cancel" : "Edit"}
  </Button>
  {isEditing && (
    <Button
      mt={4}
      onClick={handleSave}
      colorScheme="green"
      ml={2}
    >
      Save
    </Button>
  )}
</ModalBody>



        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Test;
