import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Button,
  Textarea,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { AiOutlineProfile } from "react-icons/ai";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { InfoIcon } from "@chakra-ui/icons";
import { supabase } from "../Auth/supabaseClient";
import { useSite } from "./SiteContext";

const TextareaWithGenerate = ({
  name,
  value,
  onChange,
  placeholder,
  onGenerate,
  isLoading,
}) => (
  <Box position="relative">
    <Textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      pr="40px"
      color="white"
      bg="gray.700"
    />
  </Box>
);

function ProfileComponent() {
  const { siteId } = useSite();
  const [parameters, setParameters] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [loadingFields, setLoadingFields] = useState({});
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const toast = useToast();
  const [originalParameters, setOriginalParameters] = useState([]);

  useEffect(() => {
    const fetchParameters = async () => {
      const { data, error } = await supabase
        .from("parameters")
        .select()
        .eq("site_id", siteId);
      if (error) {
        console.error("Error fetching parameters", error);
      } else {
        setParameters(data);
        setOriginalParameters(JSON.parse(JSON.stringify(data)));
      }
    };

    fetchParameters();

    const parameterSubscription = supabase
      .channel("realtime:public:parameters")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "parameters",
          filter: `site_id=eq.${siteId}`,
        },
        (payload) => {
          setParameters((prevParameters) => [...prevParameters, payload.new]);
        }
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "parameters",
          filter: `site_id=eq.${siteId}`,
        },
        (payload) => {
          setParameters((prevParameters) =>
            prevParameters.map((param) =>
              param.id === payload.new.id ? payload.new : param
            )
          );
        }
      )
      .on(
        "postgres_changes",
        {
          event: "DELETE",
          schema: "public",
          table: "parameters",
          filter: `site_id=eq.${siteId}`,
        },
        (payload) => {
          setParameters((prevParameters) =>
            prevParameters.filter((param) => param.id !== payload.old.id)
          );
        }
      )
      .subscribe();

    // Initial fetch of parameters
    fetchParameters();

    // Cleanup function to remove subscription on unmount
    return () => {
      supabase.removeChannel(parameterSubscription);
    };
  }, [siteId]);

  const handleParameterChange = (e, index) => {
    const newValue = e.target.value;
    const updatedParameters = [...parameters];
    updatedParameters[index].parameter_value = newValue;
    setParameters(updatedParameters);
    setHasChanges(true);
  };

  const handleResetChanges = () => {
    setParameters(JSON.parse(JSON.stringify(originalParameters)));
    setHasChanges(false);
  };

  const handleSaveProfile = async () => {
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from("parameters")
        .upsert(parameters, { onConflict: ["id"] });

      if (error) throw error;

      setIsSaved(true);
      setHasChanges(false);
      setOriginalParameters(JSON.parse(JSON.stringify(parameters)));
      toast({
        title: "Profile Saved",
        description: "Website profile has been updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => setIsSaved(false), 2000);
    } catch (error) {
      console.error("Error saving profile:", error);
      toast({
        title: "Error",
        description: "Failed to save website profile. Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box bg="gray.800" p={2} color="white" height="calc(100vh)">
      <HStack mb={4}>
        <AiOutlineProfile size={24} />
        <Text fontSize="lg" fontWeight="bold">
          Website Profile
        </Text>
      </HStack>
      <Text fontSize="sm" mb={4} color="gray.300">
        Your website profile drives the design, content, and SEO for all pages.
        <Tooltip label="Only new content will be affected. It will not affect existing content or pages and won't proactively change anything on your website">
          <InfoIcon ml={2} color="gray.300" />
        </Tooltip>
      </Text>
      <Box overflowY="auto" maxHeight="calc(100% - 200px)"        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.500',
            borderRadius: '24px',
          },
        }} pr={1}
>
        <VStack spacing={4} align="stretch"
        >
          {parameters.map((param, index) => (
            <Box key={index}>
              <Text mb={2}>{param.parameter_name}</Text>
              <TextareaWithGenerate
                name={param.parameter_name}
                value={param.parameter_value}
                onChange={(e) => handleParameterChange(e, index)}
                placeholder={`Enter ${param.parameter_name}`}
                isLoading={loadingFields[param.parameter_name]}
              />
            </Box>
          ))}
        </VStack>{" "}
      </Box>
      <Box display="flex" justifyContent="end" mt={4} gap={2}>
        {hasChanges && (
          <Button
            variant="outline"
            onClick={handleResetChanges}
            isDisabled={!hasChanges}
            width="125px"
          >
            Reset Changes
          </Button>
        )}

        <Button
          variant="primary"
          onClick={handleSaveProfile}
          isDisabled={!hasChanges || isSaving}
          isLoading={isSaving}
          loadingText="Saving..."
          width="150px"
        >
          {isSaved ? "Saved!" : "Save Profile"}
        </Button>
      </Box>
    </Box>
  );
}

export default ProfileComponent;



