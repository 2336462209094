// SignUpPage.js
import React from 'react';
import { useToast, Link } from '@chakra-ui/react';
import { supabase } from './supabaseClient';
import AuthForm from './AuthForm';
import { ROUTE_PATHS } from 'constants/routeConstants';
import { Link as RouterLink } from 'react-router-dom';

const SignUpPage = () => {
    const toast = useToast();

    const handleSignUp = async (event, email, password, confirmPassword) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            toast({
                title: "Password mismatch",
                description: "The passwords do not match. Please try again.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }
        const { error } = await supabase.auth.signUp({ email, password });
        if (error) {
            toast({
                title: "An error occurred.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Account created",
                description: "Please check your email to verify your account.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const links = <Link as={RouterLink} variant="ghost" colorScheme="brand" to={ ROUTE_PATHS.LOGIN }>Already have an account? Sign In</Link>;

    return <AuthForm heading="Create an account" buttonLabel="Sign Up" onFormSubmit={handleSignUp} isSignUp={true} links={links}

/>;
};

export default SignUpPage;
