import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
} from "@chakra-ui/react";
import { FiUser, FiSettings, FiMenu,  FiLayout } from "react-icons/fi";
import { Image } from "@chakra-ui/react";

import { useAuth } from "../Auth/supabaseClient";
import { ROUTE_PATHS } from "constants/routeConstants";

const Bar = () => {
  const { logout } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const SidebarContent = () => (
    <Flex direction="column" align="stretch" height="100%">
      <Image src="/logo/logotype-white.svg" alt="Siteboon Logo" mb={8} />

      <Flex direction="column" flex={1}>
        <Link to={ROUTE_PATHS.APP}>
          <Button
            leftIcon={<FiLayout />}
            variant="frostedGlass"
            mb={4}
            justifyContent="flex-start"
            width="100%"
            pl={4}
          >
            Sites
          </Button>
        </Link>
      </Flex>
      <Flex direction="column">
        <Link to={ROUTE_PATHS.SETTINGS}>
          <Button
            leftIcon={<FiSettings />}
            variant="frostedGlass"
            mb={4}
            justifyContent="flex-start"
            width="100%"
            pl={4}
            textAlign="left"
          >
            <Box as="span" ml={2}>
              Account Settings
            </Box>
          </Button>
        </Link>
        <Menu >
          <MenuButton
            as={Button}
            leftIcon={<FiUser />}
            variant="frostedGlass"

            mb={4}
            A
            justifyContent="flex-start"
            width="100%"
            pl={4}
            textAlign="left"
          
          >
            <Box as="span" ml={2}>
              Profile
            </Box>
          </MenuButton>

          <MenuList bg="white" borderColor="primary.500">
            <MenuItem _hover={{ bg: "primary.50" }}>Profile Settings</MenuItem>
            <MenuItem onClick={logout} _hover={{ bg: "primary" }}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );

  return (
    <>
      <Box
        display={{ base: "none", md: "block" }}
        bg="primary.500"
        color="white"
        py={4}
        px={4}
        position="fixed"
        top={0}
        left={0}
        height="100vh"
        width="250px"
        zIndex={1}
      >
        <SidebarContent />
      </Box>

      <Box
        display={{ base: "block", md: "none" }}
        position="fixed"
        top={4}
        left={4}
        zIndex={2}
      >
        <IconButton
          icon={<FiMenu />}
          onClick={onOpen}
          variant="outline"
          colorScheme="brand"
        />
      </Box>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="primary.800" color="white">
          <DrawerCloseButton />
          <SidebarContent />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Bar;
